import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import Button from '@wui/input/button';

const defaultOptions = [
  [true, 'Yes'],
  [false, 'No'],
];

const styles = theme => ({
  // Used to modify other classes.
  hasValue: {},

  root: {
    paddingBottom: 4,
  },

  dotRoot: {
    alignItems: 'flex-start',
  },

  dotDescription: {
    marginBottom: 24,
  },

  buttons: {
    marginTop: 4,
  },

  buttonOutlined: {
    '$hasValue &': {
      borderWidth: 1,
      boxShadow: theme.customShadows.inset,
      background: theme.palette.background.default,
    },
  },
});

class ButtonGroup extends React.Component {
  static propTypes = {
    /** Show traditional dot-style instead of boxes */
    label: PropTypes.string,
    useExternalLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,

    /** @ignore */
    classes: PropTypes.shape({}).isRequired,
    withDeselectedBackground: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    // eslint-disable-next-line react/forbid-prop-types
    options: PropTypes.arrayOf(PropTypes.array),
    pulseFirstOption: PropTypes.bool,
  };

  static defaultProps = {
    label: null,
    useExternalLabel: false,
    value: null,
    disabled: false,
    onChange: () => {},
    options: defaultOptions,
    withDeselectedBackground: true,
    pulseFirstOption: false,
  };

  get hasValue() {
    const { value } = this.props;
    return value !== null && value !== '';
  }

  selectValue = value => () => {
    const { onChange } = this.props;

    onChange(value);
  };

  renderOption = ([value, displayText], pulse, rest) => {
    const { classes, disabled, value: selectedValue, label: labelText } = this.props;
    const selected = value === selectedValue;

    const buttonStyle =
      selected && this.hasValue
        ? { variant: 'contained', color: 'primary' }
        : { variant: 'outlined' };

    const buttonClasses = {
      root: classes.buttonRoot,
      outlined: classes.buttonOutlined,
    };

    return (
      <Grid item key={value}>
        <Button
          aria-label={`${labelText} ${displayText}`}
          role="button"
          {...rest}
          noMinWidth
          {...buttonStyle}
          disabled={disabled}
          classes={buttonClasses}
          onClick={this.selectValue(value)}
          data-value={value}
          pulse={pulse}
        >
          {displayText}
        </Button>
      </Grid>
    );
  };

  render() {
    const {
      label,
      useExternalLabel,
      value,
      classes,
      options,
      withDeselectedBackground,
      pulseFirstOption,
      ...rest
    } = this.props;

    const { className: externalClassName, ...optionProps } = rest;

    const renderedOptions = options.map((opt, idx) =>
      this.renderOption(opt, pulseFirstOption && !idx, optionProps),
    );

    const className = classNames(
      classes.root,
      {
        [classes.hasValue]: this.hasValue && withDeselectedBackground,
      },
      externalClassName,
    );

    return (
      <div className={className}>
        <Grid container role="group" spacing={2} className={classes.buttons}>
          {renderedOptions}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(ButtonGroup);
