import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { documentBundleIncludes } from 'utils';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';

import { shouldShowRenderError, Urls } from 'urls';
import { useGlobalContext, useScreenSize } from 'hooks';

import KeepPlanUpdated from '@a/images/keep-plan-updated.png';
import FinishYourPlan from '@willing-shared/assets/images/finish-your-plan.png';

const Header = () => {
  const { clientData, serverData, history, analyticsEvents } = useGlobalContext();
  const { isPhoneDisplay } = useScreenSize();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (shouldShowRenderError()) {
      enqueueSnackbar('You must finish your plan before you can view your documents.', {
        variant: 'error',
      });
    }
    // We only want this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToSign = async () => {
    const { fromPortunus } = serverData;
    if (
      !fromPortunus &&
      documentBundleIncludes(serverData.documentBundle, clientData.recommendedDocumentBundle)
    ) {
      history.push(Urls.file);
    } else {
      await analyticsEvents.dispatchEvent('goneToDocuments');
      history.push(SectionDocuments.reviewUrl);
    }
  };

  if (!clientData.isPlanValid()) {
    return (
      <Grid
        container
        direction="row"
        wrap="wrap-reverse"
        alignContent="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item xs={12} sm={6} md={7}>
          <Typography variant="h2">Make a plan that protects your family and assets.</Typography>
          <Typography variant="h6">
            The documents you create will conform to your state's local regulations.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <DimensionLimiter v={isPhoneDisplay ? 177 : '100%'} h={isPhoneDisplay ? 266 : '100%'}>
            <div>
              <img alt="" src={FinishYourPlan} width="100%" />
            </div>
          </DimensionLimiter>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      direction="row"
      wrap="wrap-reverse"
      alignContent="center"
      alignItems="center"
      spacing={4}
    >
      <Grid item xs={12} sm={7}>
        <Typography variant="h2" component="h1">
          Nice Work! Review and submit your information to generate your documents
        </Typography>

        <Spacer v={24} smDown />
        <Spacer v={32} mdUp />

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={goToSign}
          fullWidth={isPhoneDisplay}
          data-path="goToSign"
        >
          Submit
        </Button>
      </Grid>

      <Grid item xs={12} sm={5}>
        <DimensionLimiter v={isPhoneDisplay ? 202 : '100%'} h={isPhoneDisplay ? 304 : '100%'}>
          <div>
            <img alt="" src={KeepPlanUpdated} width="100%" />
          </div>
        </DimensionLimiter>
      </Grid>
    </Grid>
  );
};

export default observer(Header);
