import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { Link as RouterLink, Redirect } from 'react-router-dom';

import { API } from 'API';
import Layout from '@c/layout/Layout';
import { getDigexScheduleLink } from 'utils/constants';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import NotEnrolledModal from 'ui/components/NotEnrolledModal';
import { AppLocation, DocumentBundle, DocumentType } from 'utils/enums';
import OmbudsmanModal from 'material-ui/pages/documents/OmbudsmanModal';
import { Urls } from 'urls';
import ManualSigningModal from 'material-ui/pages/documents/ManualSigningModal';
import {
  base64ToBlob,
  canNotarizeOnline,
  canProofNotarize,
  documentBundleIncludes,
  gaEvent,
  getSectionText,
  requiresOmbudsmanStates,
  getEmail,
} from 'utils';

import Hidden from '@material-ui/core/Hidden';
import WuiTheme from '@willing-shared/WuiTheme';
import Link from '@wui/basics/link';
import Button from '@wui/input/button';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import Spinner from '@wui/basics/spinner';
import Dropdown from '@wui/input/dropdown';
import CustomIcon from '@wui/basics/customIcon';
import Typography from '@wui/basics/typography';
import GenericError from '@wui/basics/genericError';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import Table from '@wui/layout/table';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import renderWuiSaveButton from 'material-ui/components/layout/renderWuiSaveButton';
import TableHeader from 'material-ui/components/dashboard/TableHeader';

import KeepPlanUpdated from '@a/images/keep-plan-updated.png';
import { ReactComponent as Pending } from '@a/images/pending.svg';
import { ReactComponent as Warning } from '@a/images/warning.svg';
import { ReactComponent as GreenTick } from '@a/images/green-tick.svg';

const styles = {
  link: {
    color: '#3C59BA',
    '&:hover': {
      color: '#213166',
    },
    '&:visited': {
      color: '#551A8B',
    },
  },
  docsTable: {
    '& + &': {
      marginTop: 24,
    },
  },
  colTitle: {
    textTransform: 'uppercase',
  },
};

class DocumentList extends React.Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    page: PropTypes.object,
    section: PropTypes.object,
    clientDataHolder: PropTypes.object,
    experiments: PropTypes.object,
    hasMlpDep: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      error: false,
      sections: null,
      showOmbudsmanModal: false,
      showManualSigningModal: false,
      showNotEnrolledModal: false,
      documentsLoading: false,
      userSelectedSchedule: false,
      completedProofSession: null,
      transactionLoading: false,
    };

    this.loadDocumentsInfo();
    this.loadProofSessionInfo();
  }

  componentDidMount() {
    API.sendUserEvent('SIGN_SECTION_SEEN');

    if (!this.onlineEligible) {
      gaEvent('online_notarization', 'not_available');
    } else {
      const { useScheduledDigitalNotarizations } = this.clientDataHolder.supportInfo;
      gaEvent(
        'online_notarization',
        useScheduledDigitalNotarizations ? 'can_schedule' : 'can_sign_now',
      );
    }
  }

  get clientDataHolder() {
    return this.context.clientDataHolder;
  }

  get clientData() {
    return this.clientDataHolder.clientData;
  }

  get serverData() {
    return this.clientDataHolder.serverData;
  }

  get signingComplete() {
    return (
      this.clientDataHolder.clientDataPristine.isNotarizationComplete ||
      this.state.completedProofSession !== null
    );
  }

  get hasTrust() {
    const { sections } = this.state;

    if (!sections) {
      return false;
    }

    return sections.some(s => s.documents.some(d => d.document_type === DocumentType.DEED));
  }

  loadDocumentsInfo() {
    this.deedCount = 1;

    API.getDocumentsInfo(DocumentBundle.HOMEOWNERS)
      .then(({ sections }) => this.setState({ sections }))
      .catch(() => this.setState({ error: true }));
  }

  loadProofSessionInfo() {
    // Drop errors since "no session" is a valid response
    API.getCompletedProofSession()
      .then(({ data }) => this.setState({ completedProofSession: data }))
      .catch(() => null);
  }

  renderHeader() {
    let title = 'Print your documents';
    let notice =
      'Your documents must be printed and signed with witnesses and a notary. Follow the instructions attached to each document to properly sign, file, and store it.';

    if (this.proofEligible) {
      title = 'Sign and notarize';
      notice = (
        <>
          Finalize your documents by having them notarized online or in-person. As a MetLife Legal
          Plans member, you can have your documents notarized online anytime, 24 hours a day, 365
          days a year.
          <br />
          <br />
          Online notarization is a virtual meeting between you, a remote online notary, and two
          witnesses (who will be provided). When you are ready to have your documents notarized,
          simply select “Sign & Notarize Now” and you’ll be asked to verify your identity and be
          connected with an online notary. It should take 45 minutes or less.
          <br />
          <br />
          If you prefer a more traditional in-person notarization, you can download or print your
          documents by selecting “Other Options”.
        </>
      );
    } else if (this.onlineEligible) {
      title = 'Sign and notarize';
      notice = (
        <>
          Finalize your documents by having them notarized online or in-person. As a MetLife Legal
          Plans member, you can have your documents notarized online with our notary team.
          <br />
          <br />
          Online notarization is a virtual meeting between you, a remote online notary, and two
          witnesses (who will be provided). Select “Schedule Notarization” to schedule a
          notarization with our online notary team. During select business hours, you may see an
          option to notarize your documents now (“Sign & Notarize Now” option).
          <br />
          <br />
          If you prefer a more traditional in-person notarization, you can download or print your
          documents by selecting “Other Options”.
        </>
      );
    }

    return (
      <>
        <Grid container spacing={1} wrap="wrap-reverse" alignItems="center">
          <Grid item sm={7} xs={12}>
            <Typography variant="h2" component="h1">
              {title}
            </Typography>

            <Typography variant="body1">{notice}</Typography>
          </Grid>
          <Grid item sm={5} xs={12}>
            <DimensionLimiter h="100%">
              <img alt="" src={KeepPlanUpdated} />
            </DimensionLimiter>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            {!this.isTabletOrSmallerDisplay && (
              <React.Fragment>
                <Spacer v={20} />
                {this.renderActions()}
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  handleSignOnlineClick = () => {
    gaEvent('sign_online', 'clicked');
    if (requiresOmbudsmanStates.includes(this.clientData.address.state)) {
      this.setState({ showOmbudsmanModal: true });
    } else {
      this.digitalSign();
    }
  };

  showManualSigningModal = () => {
    this.setState({ showOmbudsmanModal: false, showManualSigningModal: true });
  };

  ombudsmanYes = () => {
    gaEvent('nursing_facility', 'yes');
    this.showManualSigningModal();
  };

  digitalSign = () => {
    const { useScheduledDigitalNotarizations } = this.clientDataHolder.supportInfo;
    const { userSelectedSchedule } = this.state;

    const shouldSchedule =
      (useScheduledDigitalNotarizations || userSelectedSchedule) && !this.proofEligible;
    return shouldSchedule ? this.scheduleSession() : this.signOnline();
  };

  ombudsmanNo = () => {
    gaEvent('nursing_facility', 'no');
    this.digitalSign();
  };

  signOnline = () => {
    if (this.proofEligible) {
      this.setState({ transactionLoading: true });
      API.createProofSession().then(() => {
        this.clientDataHolder.props.history.push(Urls.proofNotarize);
      });
      return;
    }
    this.clientDataHolder.props.history.push(SectionNotarize.url);
  };

  scheduleSession = () => {
    window.location.replace(getDigexScheduleLink(this.serverData));
  };

  get onlineEligible() {
    const {
      allowDigitalNotarizations,
      allowFreeLookDigitalNotarizations,
    } = this.clientDataHolder.supportInfo;
    return (
      allowDigitalNotarizations &&
      canNotarizeOnline(this.clientData, this.serverData) &&
      this.context.hasMlpDep &&
      (allowFreeLookDigitalNotarizations || !this.serverData.isFreeLook)
    );
  }

  get proofEligible() {
    const {
      useProofNotarization,
      allowFreeLookDigitalNotarizations,
    } = this.clientDataHolder.supportInfo;
    return (
      useProofNotarization &&
      canNotarizeOnline(this.clientData, this.serverData) &&
      canProofNotarize(this.clientData, this.serverData) &&
      this.context.hasMlpDep &&
      (allowFreeLookDigitalNotarizations || !this.serverData.isFreeLook)
    );
  }

  renderSignOnline = () => {
    const { transactionLoading } = this.state;
    const { useScheduledDigitalNotarizations } = this.clientDataHolder.supportInfo;
    const handleClick = schedule => {
      this.setState({ userSelectedSchedule: schedule });
      this.handleSignOnlineClick();
    };

    if (this.proofEligible) {
      return (
        <Button
          variant="contained"
          color="primary"
          noMinWidth
          onClick={() => handleClick(false)}
          disabled={transactionLoading}
        >
          <strong>Sign & Notarize Now</strong>
        </Button>
      );
    }

    if (!this.onlineEligible) {
      return null;
    }

    if (useScheduledDigitalNotarizations) {
      return (
        <Button variant="contained" color="primary" onClick={this.handleSignOnlineClick} noMinWidth>
          Schedule Notarization
        </Button>
      );
    }

    return (
      <>
        <Button
          variant="contained"
          noMinWidth
          color="primary"
          onClick={() => handleClick(false)}
          disabled={transactionLoading}
        >
          <strong>Sign & Notarize Now</strong>
        </Button>
        <Spacer h={16} />
        <Button variant="outlined" color="primary" onClick={() => handleClick(true)} noMinWidth>
          Schedule Notarization
        </Button>
      </>
    );
  };

  renderPrintDownloadEmail = () => {
    const goToDocuments = async download => {
      this.setState({ error: false, documentsLoading: true });
      try {
        const response = await API.getDocumentsPdf({ digitalEstatePlanningService });

        if (download) {
          const downloadLink = document.createElement('a');
          downloadLink.href = `data:application/pdf;base64,${response.data}`;
          downloadLink.download = this.clientData.documentsFileName;
          downloadLink.click();
          gaEvent('document_generation', 'download');
        } else {
          const blob = base64ToBlob(response.data);
          const objectUrl = URL.createObjectURL(blob);
          gaEvent('document_generation', 'print');
          window.open(objectUrl);
        }
      } catch (error) {
        const action = download ? 'download' : 'print';
        gaEvent('document_generation', `${action}_failed`);
        this.handleAuthenticationError(error);
      }

      this.setState({ documentsLoading: false });
    };

    const print = () => goToDocuments();
    const download = () => goToDocuments(true);
    const email = async () => {
      this.setState({ error: false, documentsLoading: true });
      try {
        await API.emailDocuments();
        this.props.enqueueSnackbar('Email sent!', { variant: 'success' });
        gaEvent('document_generation', 'email');
      } catch (error) {
        gaEvent('document_generation', 'email_failed');
        this.handleAuthenticationError(error);
      } finally {
        this.setState({ documentsLoading: false });
      }
    };

    if (!this.onlineEligible && !this.proofEligible) {
      return (
        <React.Fragment>
          <div>
            <Button variant="contained" color="primary" onClick={print} noMinWidth>
              Print
            </Button>
            <Spacer v={16} />
          </div>

          <Spacer h={16} />

          <div>
            <Button variant="contained" color="primary" onClick={download} noMinWidth>
              {this.state.documentsLoading && <Spinner />}
              {!this.state.documentsLoading && 'Download'}
            </Button>
            <Spacer v={16} />
          </div>

          <Spacer h={16} />

          {(digitalEstatePlanningService || this.serverData.isFreeLook) && (
            <div>
              <Button variant="contained" color="primary" onClick={email} noMinWidth>
                Email
              </Button>
              <Spacer v={16} />
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <Dropdown
        renderButton={({ onClick, open }) => (
          <Button
            variant="outlined"
            noMinWidth
            aria-expanded={open ? 'true' : 'false'}
            aria-label="Other options drop down"
            onClick={onClick}
          >
            Other options
            <ArrowDropDown />
          </Button>
        )}
        options={[
          { label: 'Print', onClick: print },
          { label: 'Download', onClick: download },
          { label: 'Email', onClick: email },
        ]}
      />
    );
  };

  closeNotEnrolledModal = () => {
    this.setState({
      showNotEnrolledModal: false,
    });
  };

  handleAuthenticationError = error => {
    if (error.errorCode === 'not_authenticated') {
      this.setState({ showNotEnrolledModal: true });
    } else {
      this.setState({ error: true });
    }
  };

  renderActions() {
    if (!this.serverData.documentBundle || this.state.completedProofSession) {
      return null;
    }

    return (
      <React.Fragment>
        <Spacer v={16} mdUp />

        <Grid container direction="row" md={12}>
          {this.renderSignOnline()}

          {(this.onlineEligible || this.proofEligible) && <Spacer inline h={8} />}

          {this.renderPrintDownloadEmail()}
        </Grid>
      </React.Fragment>
    );
  }

  documentColumnTitle(section) {
    const { sections } = this.state;
    const { classes } = this.props;
    const docText =
      sections.length > 1 ? getSectionText(section.section_type, this.clientData) : 'Document';

    return <div className={classes.colTitle}>{docText}</div>;
  }

  viewDocumentLink(section, doc = null) {
    const { classes } = this.props;

    if (!doc && !this.serverData.documentBundle) {
      return null;
    } else if (doc && this.isLocked(doc)) {
      return null;
    }
    const [text, Wrapper, style, linkStyle, idDocument, role] = doc
      ? ['View', Typography, { textAlign: 'right' }, { color: 'PLACEHOLDER' }, doc, null]
      : [
          'View All',
          'div',
          { textAlign: 'right' },
          { textTransform: 'none' },
          section.documents[0],
          'columnheader',
        ];

    let documentPart = idDocument.document_type;
    if (idDocument.document_type === DocumentType.DEED) {
      documentPart += `_${this.deedCount}`;

      if (doc) {
        this.deedCount += 1;
      }
    }

    const href = `${SectionDocuments.reviewUrl}#${section.section_type}_${documentPart}`;

    return (
      <Wrapper role={role} style={style}>
        <Link to={href} style={linkStyle} className={classes.link} component={RouterLink}>
          {text}
        </Link>
      </Wrapper>
    );
  }

  renderDocumentTitle(doc) {
    return (
      <React.Fragment>
        <Typography bold={this.isPhoneDisplay}>{doc.display_name}</Typography>

        {this.isPhoneDisplay && this.renderStatus(doc)}
      </React.Fragment>
    );
  }

  isLocked(doc) {
    return doc.locked || !this.serverData.documentBundle;
  }

  renderStatusIcon(doc) {
    let icon = Pending;
    if (this.isLocked(doc)) {
      icon = Warning;
    } else if (this.signingComplete) {
      icon = GreenTick;
    }

    return <CustomIcon block src={icon} role="presentation" />;
  }

  renderStatus(doc) {
    let text = 'Ready to Sign';
    if (this.isLocked(doc)) {
      text = 'Locked';
    } else if (this.signingComplete) {
      text = 'Complete!';
    }

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>{this.renderStatusIcon(doc)}</Grid>
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderRow(section, doc) {
    if (this.isLocked(doc)) {
      return null;
    }
    return (
      <Panel role="row" lessPadding tableRow>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          alignContent="center"
          spacing={1}
        >
          <Grid item xs={8} sm={6} role="cell">
            <Grid container direction="row">
              <Grid item xs={10} sm={12}>
                <Typography bold={this.isPhoneDisplay}>{doc.display_name}</Typography>

                {this.isPhoneDisplay && this.renderStatus(doc)}
              </Grid>
            </Grid>

            <Hidden only="xs">
              <Typography>{section.description}</Typography>
            </Hidden>
          </Grid>

          <Hidden only="xs">
            <Grid item sm={3} role="cell">
              {this.renderStatus(doc)}
            </Grid>
          </Hidden>

          <Grid item xs={4} sm={3} align="center" role="cell">
            {this.viewDocumentLink(section, doc)}
          </Grid>
        </Grid>
      </Panel>
    );
  }

  renderTable = section => {
    const { classes } = this.props;
    const documentPart = section.documents[0].document_type;
    const viewAllHref = `${SectionDocuments.reviewUrl}#${section.section_type}_${documentPart}`;

    return (
      <div className={classes.docsTable} role="table">
        <TableHeader
          firstLabel={this.documentColumnTitle(section)}
          secondLabel="STATUS"
          thirdLabel="View All"
          thirdColLink={viewAllHref}
          showThirdColTitle
        />
        {section.documents.map(d => this.renderRow(section, d))}
      </div>
    );
  };

  submitSigningDate = () => {
    const {
      page: { goToNext },
    } = this.context;

    goToNext();
  };

  renderDocuments() {
    const { error, sections } = this.state;

    if (error) {
      return (
        <GenericError message="There was an error loading your documents, please try again." />
      );
    } else if (!sections) {
      return <Spinner />;
    }

    return sections.map(this.renderTable);
  }

  renderProofTable() {
    const { completedProofSession } = this.state;
    if (!completedProofSession) {
      return null;
    }

    // These fields are wrapped with keys in WUI's Table
    /* eslint-disable react/jsx-key */
    const columns = [
      { title: 'Document/Assets', sm: 6, xs: 8 },
      { title: 'Status', xs: 4 },
    ];
    const rows = completedProofSession.documents
      .map(doc => ({
        content: [<Typography>{doc.friendly_name}</Typography>, this.renderStatus(doc)],
      }))
      .concat([
        {
          content: [
            <Typography>Notary Recording</Typography>,
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <CustomIcon
                  block
                  src={completedProofSession.download_evidence_url ? GreenTick : Pending}
                />
              </Grid>
              <Grid item>
                <Typography>
                  {completedProofSession.download_evidence_url ? 'Ready' : 'Loading'}
                </Typography>
              </Grid>
            </Grid>,
          ],
        },
      ]);
    /* eslint-enable react/jsx-key */

    return (
      <Layout wide={2} padded={false} buttonRenderer={() => null} showBackButton>
        <WuiTheme>
          <Spacer v={40} />

          <Typography variant="h2" component="h1">
            Welcome back!
          </Typography>

          <Typography variant="body1" bold>
            Your documents and a recording of your notary session are available to download below.
          </Typography>

          <Typography variant="body2">
            Please note: Recording may take 3-5 minutes before it is available to download. If you
            have any questions feel free to contact us at {getEmail()}
          </Typography>

          <Spacer v={16} />

          <Table rows={rows} columns={columns} />

          <Spacer v={16} />

          <Grid container direction="row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location = completedProofSession.documents_zip_url;
              }}
              noMinWidth
            >
              Download Documents
            </Button>

            <Spacer h={16} v={16} />

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location = completedProofSession.download_evidence_url;
              }}
              disabled={!completedProofSession.download_evidence_url}
              noMinWidth
            >
              Download Recording
            </Button>
          </Grid>
        </WuiTheme>
      </Layout>
    );
  }

  render() {
    if (!this.clientData.isPlanValid()) {
      return <Redirect to={Urls.planDashboard} />;
    }

    if (this.clientData.isSectionValid(SectionNotarize)) {
      return <Redirect to={this.clientDataHolder.docsUrl} />;
    }

    const { showOmbudsmanModal, showManualSigningModal, completedProofSession } = this.state;
    const { documentBundle } = this.serverData;

    const includeSpouse =
      this.clientData.isPlanningForSpouse &&
      documentBundleIncludes(documentBundle, DocumentBundle.COUPLES);

    const orSpouse = includeSpouse && `or ${this.clientData.spouse.firstName}`;
    const isUpwise = this.clientData.covidEmployer === 'upwise';
    const secondaryLink = isUpwise ? '#close' : `${window.WillingConfig.membersAppLink}/services`;
    const secondaryText = isUpwise ? 'Return to Upwise' : 'Exit to main menu';
    const showSecondaryButton = this.serverData.fromPortunus && !digitalEstatePlanningService;

    if (completedProofSession) {
      return this.renderProofTable();
    }

    return (
      <Layout
        wide={2}
        padded={false}
        nextButtonText="I've already signed"
        appLocation={AppLocation.DOCUMENTS}
        buttonRenderer={renderWuiSaveButton}
        showBackButton
        primaryButtonProps={{
          variant: 'outlined',
        }}
        secondaryButtonProps={
          showSecondaryButton && {
            variant: 'outlined',
            color: 'primary',
            onClick: () => {
              window.location = secondaryLink;
            },
            children: secondaryText,
            size: 'medium',
          }
        }
      >
        <NotEnrolledModal
          open={this.state.showNotEnrolledModal}
          closeModal={this.closeNotEnrolledModal}
        />
        <WuiTheme>
          <OmbudsmanModal
            open={showOmbudsmanModal}
            onClose={() => this.setState({ showOmbudsmanModal: false })}
            showManualSigningModal={this.ombudsmanYes}
            orSpouse={orSpouse}
            digitalSign={this.ombudsmanNo}
          />
          <ManualSigningModal
            open={showManualSigningModal}
            onClose={() => this.setState({ showManualSigningModal: false })}
          />
          <Spacer v={40} />
          {this.renderHeader()}
          {this.isTabletOrSmallerDisplay && this.renderActions()}
          <Spacer v={16} />
          {this.renderDocuments()}
        </WuiTheme>
      </Layout>
    );
  }
}

export default withStyles(styles)(withSnackbar(withIsMobileDisplay(DocumentList)));
