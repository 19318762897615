import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { API } from 'API';

import { Urls } from 'urls';

import Typography from '@wui/basics/typography';
import { useGlobalContext } from 'hooks';

const ProofLobby = () => {
  const { history } = useGlobalContext();
  const [session, setSession] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const updateSession = async () => {
      try {
        const response = await API.getProofSession();
        setSession(response.data);
        setError(false);
      } catch (e) {
        if (e.errorCode === 'no_session') {
          history.push(Urls.signSection);
        } else {
          setError(true);
        }
      }
    };

    const interval = setInterval(updateSession, 2000);
    return () => {
      clearInterval(interval);
    };
  }, [history]);

  useEffect(() => {
    if (session && session.transaction_access_link) {
      window.location.replace(session.transaction_access_link);
    }
  }, [session]);

  if (error) {
    return (
      <>
        <Typography variant="h1" align="center">
          Oops! Something went wrong
        </Typography>
      </>
    );
  }

  return (
    <Typography variant="h1" align="center">
      One moment, your documents are being prepared
    </Typography>
  );
};

export default observer(ProofLobby);
