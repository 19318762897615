import React from 'react';
import PropTypes from 'prop-types';

import { validatePersonNameUniqueness } from '!m/utils';

import FieldGroup from '../FieldGroup';
import BoundNameField from '@c/BoundNameField';
import BoundTextField from '@c/BoundTextField';
import BoundRadioGroup from '@c/BoundRadioGroup';
import withHandleNameConflicts from 'hocs/withHandleNameConflicts';

class Spouse extends React.Component {
  static contextTypes = {
    testator: PropTypes.object,
    experiments: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  static propTypes = {
    handleNameConflicts: PropTypes.func.isRequired,
  };

  nameValidations() {
    return [
      validatePersonNameUniqueness(
        this.context.clientDataHolder.clientData,
        this.context.testator.spouse,
      ),
    ];
  }

  get delayedMaritalStatus() {
    return this.context.experiments.delayedMaritalStatus;
  }

  get maritalStatusSet() {
    return Boolean(this.context.testator.maritalStatus);
  }

  get areInitialFieldsDisabled() {
    return this.delayedMaritalStatus && !this.maritalStatusSet;
  }

  get isPlanningForSpouseDisabled() {
    // Don't enable the question until the user has
    //   entered something for the name and gender
    //   fields.
    return !this.context.testator.spouse.name;
  }

  showSpouseFields(checkDelayed = false) {
    // If the marital status is delayed and unset,
    //   the fields are shown in their disabled
    //   state.
    if (checkDelayed && this.delayedMaritalStatus && !this.maritalStatusSet) {
      return true;
    }

    // If the user is not married, questions
    //   about the spouse are not needed at
    //   all.
    return this.context.testator.isMarried;
  }

  render() {
    if (!this.showSpouseFields()) {
      // If the marital status question is
      //   delayed, then the fields are shown.
      if (!this.delayedMaritalStatus) {
        return null;
      }
    }

    const { handleNameConflicts } = this.props;

    const forSpouse = this.context.testator.isPlanningForSpouse;

    const showIncomeRange = this.context.experiments.incomeRange && forSpouse;
    const showLifeInsurance = this.context.experiments.lifeInsuranceStatus && forSpouse;

    return (
      <FieldGroup level={1} title="Your Spouse">
        {this.delayedMaritalStatus && (
          <BoundTextField path="maritalStatus" label="Marital Status" />
        )}

        {this.showSpouseFields(true) && (
          <React.Fragment>
            <BoundNameField
              label="Spouse's Full Name"
              obj={this.context.testator.spouse}
              disabled={this.areInitialFieldsDisabled}
              additionalValidations={this.nameValidations()}
              onBlur={handleNameConflicts(this.context.testator.spouse)}
            />
            <BoundRadioGroup
              path="isPlanningForSpouse"
              disabled={this.isPlanningForSpouseDisabled}
              label="Do you want to create documents for both of you?"
            />
            {showIncomeRange && (
              <BoundTextField path="spouse.annualIncome" label="Average Annual Income" />
            )}
            {showLifeInsurance && (
              <BoundRadioGroup
                path="spouse.hasLifeInsurance"
                label="Does your spouse currently own life insurance?"
              />
            )}
          </React.Fragment>
        )}
      </FieldGroup>
    );
  }
}

export default withHandleNameConflicts(Spouse);
