import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tunnel } from 'react-tunnels';
import { withStyles } from '@material-ui/core/styles';
import { isWidthDown } from '@material-ui/core/withWidth';

import Grid from '@wui/layout/grid';
import Panel from '@wui/layout/panel';
import Modal from '@wui/basics/modal';
import Spacer from '@wui/layout/spacer';
import CustomIcon from '@wui/basics/customIcon';
import Typography from '@wui/basics/typography';
import TabDivider from '@wui/layout/tabDivider';
import PendingModal from '@wui/basics/pendingModal';

import Urls from 'urls';
import { getEmail, onSpaceOrEnterKeyDown } from 'utils';
import { AppLocation } from 'utils/enums';
import { getPlanSections } from 'ui/sections/sections';
import SectionSign from 'ui/sections/sign/SectionSign';
import PlanHealth from 'material-ui/components/planHealth';
import AboutYou from 'ui/sections/about-you/pages/AboutYou';
import { locationInfo } from 'utils/locations';
import RealEstate from 'ui/sections/about-you/pages/RealEstate';
import SectionNotify from 'ui/sections/file/notify/SectionNotify';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import AboutYourFamily from 'ui/sections/about-you/pages/AboutYourFamily';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

import { ReactComponent as TickIcon } from '@a/images/tick.svg';
import { ReactComponent as HomeIcon } from '@a/images/home.svg';
import { ReactComponent as PendingIcon } from '@a/images/pending.svg';
import { ReactComponent as GreenTickIcon } from '@a/images/green-tick.svg';

const styles = theme => ({
  item: {
    cursor: 'default',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.background.default,
    },
  },
  active: {
    margin: '-1px !important',
    zIndex: theme.zIndex.above,
  },
  planHealth: {
    padding: [[16, 24]],
  },
  // Largely ripped off of Bootstrap5
  visuallyHidden: {
    position: ['absolute', '!important'],
    width: ['1px', '!important'],
    height: ['1px', '!important'],
    padding: ['0px', '!important'],
    margin: ['-1px', '!important'],
    overflow: ['hidden', '!important'],
    clip: ['rect(0,0,0,0)', '!important'],
    whiteSpace: ['nowrap', '!important'],
    border: [['0'], '!important'],
  },
});

class WuiProgressPlaceholder extends React.Component {
  static contextTypes = {
    experiments: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    width: PropTypes.bool.isRequired,
    appLocation: PropTypes.string,
    showPlanHealth: PropTypes.bool,
  };

  static defaultProps = {
    appLocation: null,
    showPlanHealth: true,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      showDocumentPopup: false,
      showRevokeDigexPopup: false,
    };
  }

  renderPlanHealth() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <div className={classes.planHealth}>
          <PlanHealth meterId="progress" />
        </div>
        <TabDivider noMargin />
        <Spacer v={16} />
      </React.Fragment>
    );
  }

  get showPlanHealth() {
    const { width, showPlanHealth } = this.props;
    const { clientData, serverData } = this.context.clientDataHolder;
    return (
      !isWidthDown('md', width) &&
      showPlanHealth &&
      !clientData.covidEmployer &&
      !serverData.fromPortunus
    );
  }

  completePlan = () => {
    const { clientDataHolder, experiments } = this.context;

    this.setState({ showDocumentPopup: false });
    if (!clientDataHolder.clientData.isSectionValid(SectionAboutYou)) {
      return clientDataHolder.props.history.push(SectionAboutYou.url);
    }
    for (let section of getPlanSections(clientDataHolder.clientData, experiments)) {
      if (!clientDataHolder.clientData.isSectionValid(section)) {
        return clientDataHolder.props.history.push(section.url);
      }
    }
    return clientDataHolder.props.history.push(Urls.planDashboard);
  };

  handleClick = (id, url, clickable) => () => {
    if (id === this.props.appLocation) {
      return;
    }

    const { hasNotarizedOnline, hasProofNotarized } = this.context.clientDataHolder.serverData;
    const allowedPostDigexLocation = [AppLocation.HOME, AppLocation.DOCUMENTS].includes(id);

    if ((hasNotarizedOnline || hasProofNotarized) && !allowedPostDigexLocation) {
      this.setState({ showRevokeDigexPopup: true });
      return;
    }

    if (clickable) {
      this.context.clientDataHolder.props.history.push(url);
    } else if (id === AppLocation.DOCUMENTS) {
      this.setState({ showDocumentPopup: true });
    }
  };

  renderItem = item => {
    const { serverData } = this.context.clientDataHolder;
    const { classes, appLocation } = this.props;
    const { id, completed, clickable } = item;
    let { url, name } = locationInfo(serverData)[id];

    const active = id === appLocation;

    let iconProps = {
      src: completed ? GreenTickIcon : PendingIcon,
      height: 16,
      width: 16,
      role: 'presentation',
    };
    if (id === AppLocation.HOME) {
      iconProps = {
        src: HomeIcon,
        height: 18,
        width: 18,
        role: 'presentation',
      };
      if (serverData.documentBundle) {
        url = Urls.file;
      }
    }
    if (active && id !== AppLocation.HOME) {
      if (completed) {
        iconProps.src = TickIcon;
      }

      iconProps.color = theme => theme.palette.blue.active;
    }

    const itemClass = classNames(classes.item, {
      [classes.clickable]: clickable,
      [classes.active]: active,
    });

    const ariaLabel = active
      ? 'Current page'
      : `${completed ? 'Completed' : 'In Progress'} section`;

    return (
      <Panel
        key={url}
        paddingless
        noMargin
        special={active}
        indicatorVariant="alternate"
        onClick={this.handleClick(id, url, clickable)}
        onKeyDown={e => onSpaceOrEnterKeyDown(e, this.handleClick(id, url, clickable))}
        borderless={!active}
        className={itemClass}
        indicatorMovesContent={false}
        tabIndex="0"
        role="link"
      >
        <Spacer v={6} />
        <Grid container direction="row" alignItems="center">
          <Spacer h={24} />
          <CustomIcon {...iconProps} />
          <Spacer h={16} />
          <Typography color="#525252">
            <span className={classes.visuallyHidden}>{ariaLabel}</span>
            <span>{active ? <strong>{name}</strong> : name}</span>
          </Typography>
        </Grid>
        <Spacer v={6} />
      </Panel>
    );
  };

  render() {
    const { width } = this.props;
    const { showDocumentPopup, showRevokeDigexPopup } = this.state;
    const { clientData, serverData } = this.context.clientDataHolder;
    const pages = [
      {
        id: AppLocation.HOME,
        clickable: true,
        completed: true,
      },
      {
        id: AppLocation.ABOUT_YOU,
        completed: clientData.isPageValid(SectionAboutYou, AboutYou),
        clickable: true,
      },
      {
        id: AppLocation.FAMILY,
        completed: clientData.isPageValid(SectionAboutYou, AboutYourFamily),
        clickable: clientData.isPageValid(SectionAboutYou, AboutYou),
      },
      {
        id: AppLocation.REAL_ESTATE,
        completed: clientData.isPageValid(SectionAboutYou, RealEstate),
        clickable: clientData.isPageValid(SectionAboutYou, AboutYourFamily),
      },
    ];
    const sections = getPlanSections(clientData, this.context.experiments)
      .filter(section => section !== SectionAboutYou)
      .map(section => ({
        id: section.appLocation,
        completed: clientData.isSectionValid(section),
        clickable: clientData.isSectionValid(SectionAboutYou),
      }));

    const items = pages.concat(sections);

    items.push({
      id: AppLocation.DOCUMENTS,
      completed: clientData.isSectionValid(SectionSign),
      clickable: clientData.isPlanValid(),
    });

    if (serverData.documentBundle && !clientData.covidEmployer && !serverData.fromPortunus) {
      items.push({
        id: AppLocation.FAMILY_CENTER,
        completed: clientData.isSectionValid(SectionNotify),
        clickable: true,
      });
    }

    return (
      <Tunnel id="wui-progress">
        <PendingModal
          open={showDocumentPopup}
          title="Your documents are not ready"
          onClose={() => this.setState({ showDocumentPopup: false })}
          onClick={this.completePlan}
        >
          <Typography variant="subtitle1" align="center">
            Your documents will become available once you complete all of the prior sections.
          </Typography>
        </PendingModal>
        <Modal
          title="Make changes to your documents"
          open={showRevokeDigexPopup}
          onClose={() => this.setState({ showRevokeDigexPopup: false })}
        >
          <Typography variant="subtitle1" align="center">
            In order to make changes to your signed estate plan, you will first need to revoke the
            existing documents. If you'd like to proceed, please send us an email at {getEmail()}.
          </Typography>
        </Modal>
        <Panel paddingless borderless={isWidthDown('md', width)}>
          {this.showPlanHealth ? this.renderPlanHealth() : <Spacer v={12} />}
          {items.map(this.renderItem)}
          <Spacer v={24} />
        </Panel>
      </Tunnel>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(WuiProgressPlaceholder));
