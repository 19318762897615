import React from 'react';
import { Redirect } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Panel from '@wui/layout/panel';
import Button from '@wui/input/button';
import Grid from '@wui/layout/grid';
import CloseButton from '@wui/basics/closeButton';

import { API } from 'API';
import { Urls } from 'urls';
import WuiTheme from '@willing-shared/WuiTheme';
import {
  changeName,
  documentBundleIncludes,
  getPhoneNumber,
  getSectionText,
  onSpaceOrEnterKeyDown,
} from 'utils';
import { PersonRole, DocumentType, SectionType } from 'utils/enums';
import WizardPage from 'ui/components/wizard/WizardPage';
import { Relationship } from 'models/client-data/enums';
import SectionSign from 'ui/sections/sign/SectionSign';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import CheckoutPicker from 'ui/CheckoutPicker';
import { debugLog } from 'debug';
import classNames from 'classnames';
import PageVisit from 'ui/components/wizard/PageVisit';
import { invalidatePostPlanSections } from 'models/client-data/invalidator';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import SectionGuardians from 'ui/sections/guardians/SectionGuardians';
import SectionExecutors from 'ui/sections/executors/SectionExecutors';
import SectionHealthcare from 'ui/sections/healthcare/SectionHealthcare';
import AboutYourFamily from 'ui/sections/about-you/pages/AboutYourFamily';
import SpouseExecutors from 'material-ui/sections/executors/SpouseExecutors';
import SpouseGuardians from 'material-ui/sections/guardians/SpouseGuardians';
import BequestInterface from 'ui/sections/bequest-interface/BequestInterface';
import SpouseHealthcare from 'material-ui/sections/healthcare/SpouseHealthcare';
import MaterialGuardians from 'material-ui/sections/guardians/MaterialGuardians';
import MaterialExecutors from 'material-ui/sections/executors/MaterialExecutors';
import MaterialHealthcare from 'material-ui/sections/healthcare/MaterialHealthcare';
import SpouseBequestInterface from 'ui/sections/bequest-interface/SpouseBequestInterface';
import SectionAssetBeneficiaries from 'ui/sections/asset-beneficiaries/SectionAssetBeneficiaries';
import NotEnrolledModal from 'ui/components/NotEnrolledModal';

import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';
import DocumentModal from './DocumentModal/DocumentModal';
import DocumentPopUp from './DocumentPopUp/DocumentPopUp';
import FocusTrap from 'focus-trap-react';

import 'ui/Sign.scss';

const constSection = ['client', 'spouse', 'joint'];
const constDocument = [
  DocumentType.DEED,
  DocumentType.ADVANCE_HEALTHCARE_DIRECTIVE,
  DocumentType.POWER_OF_ATTORNEY,
  DocumentType.TRUST,
  DocumentType.WILL,
];

function classNameIncludes(element, query) {
  if (!element || !element.className) {
    return false;
  }

  return element.className.split(' ').indexOf(query) > -1;
}

class Review extends WizardPage {
  static baseUrl = Urls.reviewBase;
  static showPrevNextButtons = false;

  static cardClass =
    'card--fixed-height card--no_bottom_margin card--no-padding card--avoid-zendesk-button';
  static wizardPageClass = 'wizard-page--full-height';

  constructor(props, context) {
    super(props, context);

    this.state = {
      renderError: false,
      // Done here instead of FinalReview because of the complex render method
      showBeneficiaryAddressNotice: false,
      showMobileNav: null,
      isLaptopOrLower: null,
      showNotEnrolledModal: false,
      activeDocument: '',
      docsNav: [],
      activePerson: null,
      isUpdatingAction: false,
      documentPopUp: {
        active: false,
        type: '',
        data: {},
      },
      experimentsLoaded: false,
      processing: false,
    };
    this.mapDocumentPosition = [];

    this.analyticsEvents.dataLayer.push({
      event: 'willing.trigger.addresses-post-checkout-experiment',
    });

    // Delays the rendering of the action buttons in FinalReview until the experiment status is known
    setTimeout(() => this.setState({ experimentsLoaded: true }), this.context.experiments.delay);
  }

  get documentBundle() {
    return this.serverData.documentBundle;
  }

  get checkoutUrl() {
    return `${CheckoutPicker.url}?b=${this.clientData.recommendedDocumentBundle}`;
  }

  updateWindowSize = () => {
    this.setState({ isLaptopOrLower: window.innerWidth < 1120 });
  };

  getDocText = async reMap => {
    const data = await API.getDocuments().catch(() => ({
      renderError: true,
    }));

    if (data.renderError) {
      debugLog('Error rendering documents!');

      this.setState({
        renderError: true,
      });

      return;
    }

    this.setState(
      {
        docText: { __html: data.data.html },
        docsNav: reMap ? [] : this.state.docsNav,
      },
      () => this.generateDocumentNavigation(reMap),
    );
  };

  returnToPlanSections = async () => {
    this.updateClientData(() => {
      invalidatePostPlanSections(this.clientData);
    });

    await this.clientDataHolder.persistClientData();

    // Wizard pages don't have this.props.history
    this.clientDataHolder.props.history.push(Urls.planDashboard);
  };

  handleDocumentClick(locked, type, section) {
    if (locked) {
      this.clientDataHolder.props.history.push(this.checkoutUrl);
    } else {
      this.toggleNavigation();
      this.goToDocument({ type, section });
    }
  }

  get hasLockedDocuments() {
    return this.state.docsNav.some(({ documents }) => documents.some(doc => doc.locked));
  }

  renderBeneficiaryAddressNotice() {
    const goToAddresses = () =>
      this.clientDataHolder.props.history.push(Urls.independentBeneficiaryAddresses);

    return (
      <div className="popup-fuzz">
        <Popup showCloseIcon={false}>
          <h4 className="center">Add addresses for your beneficiaries</h4>

          <p className="small center">
            One last thing for your will to be legally binding in your state.
          </p>

          <PureButton className="button--primary center" onClick={goToAddresses}>
            Add Addresses
          </PureButton>
        </Popup>
      </div>
    );
  }

  continue = async () => {
    let url =
      documentBundleIncludes(this.documentBundle, this.clientData.recommendedDocumentBundle) ||
      this.clientData.covidEmployer ||
      this.serverData.fromPortunus
        ? SectionSign.url
        : this.checkoutUrl;

    if (this.serverData.depByDefault) {
      url = Urls.depByDefaultSummary;
    }

    this.updateClientData(() => {
      const visit = new PageVisit(SectionDocuments, Review);
      this.clientData.setPageVisitValid(visit);
      this.clientData.setSectionValid(SectionDocuments);
    });

    try {
      await this.clientDataHolder.persistClientData();
    } finally {
      this.clientDataHolder.props.history.push(url);
    }
  };

  renderNextSteps() {
    return (
      <WuiTheme>
        <Panel special="force" paddingless style={{ padding: 16 }} className="next-steps-container">
          <p className="next-steps-container__questions">Have legal questions about your plan?</p>
          <p className="next-steps-container__next-steps">
            Select “View Next Steps” below and review your plan with an attorney or finish up and
            notarize.
          </p>
        </Panel>
      </WuiTheme>
    );
  }

  renderActionButtons() {
    if (this.serverData.depByDefault) {
      return (
        <WuiTheme>
          <Grid container direction="column" alignItems="center" className="review-action-buttons">
            {this.renderNextSteps()}
            <Button
              onClick={this.returnToPlanSections}
              style={{ padding: '14px 32px', margin: '6px 12px' }}
            >
              <span
                style={{
                  color: '#4C84F5',
                  textDecoration: 'underline',
                  textDecorationColor: '#4C84F5',
                }}
              >
                Make Changes
              </span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.continue}
              dataPath="continue"
              style={{ padding: '14px 32px', margin: '6px 12px' }}
            >
              Next
            </Button>
          </Grid>
        </WuiTheme>
      );
    }

    const classes = classNames(
      'button--primary',
      'button--fullwidth',
      'button--nomargin',
      'review-action-button',
      'button--focus-outline-inside',
    );

    return (
      <div className="review-action-buttons">
        <PureButton
          className="button--tertiary button--fullwidth button--nomargin button--focus-outline-inside"
          onClick={this.returnToPlanSections}
          type="button"
        >
          Make Changes
        </PureButton>
        <PureButton className={classes} dataPath="continue" onClick={this.continue} type="button">
          {this.serverData.fromPortunus ? 'Finish' : 'Continue'}
        </PureButton>
      </div>
    );
  }

  startChat = () => {
    if (window.$zopim) {
      window.$zopim.livechat.setName(this.testator.name);
      window.$zopim.livechat.setEmail(this.testator.email);
      window.$zopim.livechat.window.show();
      window.$zopim.livechat.say("My documents aren't rendering. Please help.");
    }
  };

  renderDocumentNavigation() {
    const { docsNav, activeDocument, renderError, isLaptopOrLower } = this.state;

    const getDocumentLabelText = (sectionType, docTitle) => {
      switch (sectionType) {
        case SectionType.CLIENT:
          if (this.clientData.isPlanningForSpouse) {
            return `${this.clientData.firstName}'s ${docTitle}`;
          }
          return docTitle;
        case SectionType.SPOUSE:
          return `${this.clientData.spouse.firstName}'s ${docTitle}`;
        case SectionType.JOINT:
          return `Joint ${docTitle}`;
        default:
          return '';
      }
    };

    if (renderError) {
      return (
        <div className="error-display">
          There was an error generating your documents. Please call us at{' '}
          {getPhoneNumber(this.clientData, this.serverData)} or click below to start chatting.
          <br />
          <br />
          <button onClick={this.startChat}>Start Chat</button>
        </div>
      );
    }

    if (!docsNav.length) {
      return null;
    }

    const navClasses = classNames(
      'nav-review',
      this.toggleNavigationClass(),
      !this.state.showMobileNav && isLaptopOrLower ? 'hidden' : '',
    );

    return (
      <FocusTrap active={this.state.showMobileNav}>
        <div className={navClasses}>
          <div className="nav-review__contents">
            <div className="nav-review__title">
              {this.serverData.depByDefault && 'Review '}
              Review Before Continuing
            </div>
            {isLaptopOrLower && <CloseButton onClick={this.toggleNavigation} position="top" />}
            <div className="nav-review__wrapper">
              {docsNav.map(({ section, documents }) => (
                <div key={section} className="nav-review__section">
                  <h5
                    className="section__text"
                    onClick={() =>
                      this.goToDocument({
                        type: documents[0].type,
                        section: section,
                      })
                    }
                  >
                    <a href={`#${section}_${documents[0].type}`}>
                      {getSectionText(section, this.clientData)}
                    </a>
                  </h5>
                  {documents.map(({ type, title, address, locked }) => (
                    <div key={type} className="nav-review__document">
                      {locked && this.documentBundle ? (
                        <span className="document__lock" />
                      ) : (
                        <span className="document__lock hidden" />
                      )}
                      <a
                        onClick={() => this.handleDocumentClick(locked, type, section)}
                        href={`#${section}_${type}`}
                        className={`document__text ${
                          activeDocument === `${section}_${type}` && 'active'
                        }`}
                        aria-label={getDocumentLabelText(section, title)}
                      >
                        {title}
                        {address && <div>({address})</div>}
                      </a>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {this.serverData.depByDefault ? (
              this.renderNextSteps()
            ) : (
              <div className="nav-review__footer nav-review__footer--new">
                See your options to notarize, download, and print on the next screen.
                <br />
              </div>
            )}
          </div>
          {this.renderActionButtons()}
        </div>
      </FocusTrap>
    );
  }

  handleAuthenticationError = error => {
    if (error.errorCode === 'not_authenticated') {
      this.setState({ showNotEnrolledModal: true });
    }
  };

  componentDidMount() {
    super.componentDidMount();

    this.analyticsEvents.dispatchEvent('viewedDocuments');
    if (this.clientData.isPlanValid()) {
      this.getDocText();
    }
    this.reviewNode = document.getElementById('review__id');
    if (this.reviewNode) {
      this.reviewNode.addEventListener('scroll', this.followScrollPosition);
      this.reviewNode.addEventListener('click', this.closeModal);
    }
    this.updateWindowSize();
    window.addEventListener('resize', this.updateWindowSize);
  }

  componentWillUnmount = () => {
    super.componentWillUnmount();
    if (this.reviewNode) {
      this.reviewNode.removeEventListener('scroll', this.followScrollPosition);
      this.reviewNode.removeEventListener('click', this.closeModal);
    }
    window.removeEventListener('resize', this.updateWindowSize);
  };

  closeModal = event => {
    if (event && this.checkElement(event)) {
      return;
    }
    if (this.selectedNode) {
      this.selectedNode.classList.remove('selected');
    }
    this.setState({ activePerson: null });
  };

  openModal = (event, node) => {
    if (this.checkElement(event)) {
      return;
    }
    event.stopPropagation();
    if (this.selectedNode) {
      this.selectedNode.classList.remove('selected');
    }
    node.classList.add('selected');
    this.selectedNode = node;

    const { activePerson } = this.state;

    if (activePerson) {
      this.setState({ activePerson: null }, () => {
        if (node) {
          this.setState({ activePerson: node });
        }
      });
    } else {
      this.setState({ activePerson: node });
    }
  };

  checkElement = event => {
    let stopDocEvent = false;
    if (event.path) {
      stopDocEvent = event.path.find(el => classNameIncludes(el, 'doc__action--stop'));
    } else {
      if (classNameIncludes(event.target, 'person')) {
        return false;
      }

      let node = event.target.parentNode;
      if (classNameIncludes(node, 'review')) {
        return false;
      }

      stopDocEvent = false;
      while (!stopDocEvent && node) {
        if (classNameIncludes(node, 'portal__wrapper') || classNameIncludes(node, 'review')) {
          break;
        }
        if (classNameIncludes(node, 'doc__action--stop')) {
          stopDocEvent = true;
          break;
        }
        node = node.parentNode;
      }
    }

    return Boolean(stopDocEvent);
  };

  onKeyDown = (event, node) => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      this.openModal(event, node);
    }
  };

  generateDocumentNavigation = reMap => {
    const { docsNav } = this.state;

    if (!reMap && (!this.reviewNode || docsNav.length > 0)) {
      return;
    }

    let deedsCount = 1;
    let sectionData = {
      section: '',
      documents: [],
    };

    // parse through the HTML nodes, pulling out nodes to use to build the nav bar
    // TODO: don't do this
    for (let i = 0, children = this.reviewNode.children; i < children.length; i++) {
      const element = children[i];
      const { sectionType, documentType, address } = element.dataset;
      const locked = element.hasAttribute('data-document-locked');

      // pull out section nodes to make the headers for the side nav (client, spouse, joint)
      if (sectionType && constSection.indexOf(sectionType) !== -1) {
        if (sectionData.section) {
          docsNav.push(sectionData);
          sectionData = {
            section: '',
            documents: [],
          };
        }
        sectionData.section = sectionType;
      }

      // collect documents under the latest section
      if (documentType && constDocument.indexOf(documentType) !== -1) {
        let docType = '';
        let docTitle = DocumentType.displayName(documentType);

        if (documentType === DocumentType.DEED) {
          docType = `${documentType}_${deedsCount}`;
          deedsCount += 1;
        } else {
          docType = documentType;
        }

        this.mapDocumentPosition.push({
          element,
          value: `${sectionData.section}_${docType}`,
        });

        element.id = `${sectionData.section}_${docType}`;

        sectionData.documents.push({
          type: docType,
          title: docTitle,
          address,
          locked,
          ref: element,
        });
      }
    }
    if (sectionData.section) {
      docsNav.push(sectionData);
    }

    this.mapDocumentPosition.reverse();

    const defaultDocument = 'client_will';
    const activeDocument = document.location.hash.replace('#', '') || defaultDocument;

    this.setState({ docsNav, activeDocument }, () => {
      if (activeDocument === defaultDocument) {
        return;
      }

      const element = document.getElementById(activeDocument);

      if (element) {
        element.scrollIntoView();
      }
    });
  };

  toggleNavigationClass = () => {
    if (this.state.showMobileNav === true) {
      return 'in';
    }
    if (this.state.showMobileNav === false) {
      return 'out';
    }
    return '';
  };

  toggleNavigation = () => {
    if (this.state.isLaptopOrLower) {
      this.setState({ showMobileNav: !this.state.showMobileNav });
    } else {
      this.setState({ showMobileNav: false });
    }

    if (!this.state.showMobileNav && this.state.isLaptopOrLower) {
      this.reviewNode.addEventListener('click', this.toggleNavigation);
    } else {
      this.reviewNode.removeEventListener('click', this.toggleNavigation);
    }
  };

  goToDocument = ({ type, section }) => this.setState({ activeDocument: `${section}_${type}` });

  followScrollPosition = event => {
    const element = event.target || event.srcElement;
    const top = element.scrollTop;
    const { activeDocument } = this.state;

    const newActive = this.mapDocumentPosition.find(
      ({ element: documentElement }) => documentElement.offsetTop <= top,
    );

    if (newActive && activeDocument !== newActive.value) {
      this.setState({ activeDocument: newActive.value });
    }
  };

  toggleDocumentPopUp = (type = '', data = {}) =>
    this.setState({
      documentPopUp: {
        active: Boolean(type),
        type,
        data,
      },
      isUpdatingAction: false,
    });

  closeNotEnrolledModal = () => {
    this.setState({
      showNotEnrolledModal: false,
    });
  };

  goToReplacePage = () => {
    const {
      activePerson: { dataset },
    } = this.state;
    const forSpouse = dataset.owner === this.clientData.spouse.ownerString;

    let url = Urls.planDashboard;
    if (dataset.role === PersonRole.BENEFICIARY) {
      const page = forSpouse ? SpouseBequestInterface : BequestInterface;
      url = new PageVisit(SectionAssetBeneficiaries, page).url;
    } else if (dataset.role === PersonRole.GUARDIAN) {
      const page = forSpouse ? SpouseGuardians : MaterialGuardians;
      url = new PageVisit(SectionGuardians, page).url;
    } else if (dataset.role === PersonRole.EXECUTOR) {
      const page = forSpouse ? SpouseExecutors : MaterialExecutors;
      url = new PageVisit(SectionExecutors, page).url;
    } else if (dataset.role === PersonRole.HEALTHCARE_AGENT) {
      const page = forSpouse ? SpouseHealthcare : MaterialHealthcare;
      url = new PageVisit(SectionHealthcare, page).url;
    } else if (dataset.clientRelationship === Relationship.SELF) {
      url = SectionAboutYou.url;
    } else if ([Relationship.SPOUSE, Relationship.CHILD].includes(dataset.clientRelationship)) {
      url = new PageVisit(SectionAboutYou, AboutYourFamily).url;
    }
    this.clientDataHolder.props.history.push({
      pathname: url,
      state: {
        referredByReview: true,
      },
    });
  };

  handleUpdatePersonName = async () => {
    this.setState({ isUpdatingAction: true });
    const {
      documentPopUp: { data },
    } = this.state;

    this.updateClientData(() => {
      changeName(this.clientData, data.fullName, data.personUUID);
    });

    const response = await this.clientDataHolder.persistClientData();

    this.closeModal();

    if (response.success) {
      this.mapDocumentPosition = [];
      await this.getDocText(true);
      this.toggleDocumentPopUp('confirm_update', data);
    } else {
      this.toggleDocumentPopUp(response.error, data);
    }
  };

  renderPage() {
    if (!this.clientData.isPlanValid()) {
      return <Redirect to={Urls.planDashboard} />;
    }

    const { activePerson, documentPopUp, isUpdatingAction } = this.state;

    const containerClasses = classNames(
      'review',
      'doc-container',
      this.documentBundle ? 'paid' : 'unpaid',
    );

    return (
      <div className={this.serverData.depByDefault ? 'dep-by-default' : 'not-dep-by-default'}>
        {this.state.showError && this.renderError()}
        <NotEnrolledModal
          open={this.state.showNotEnrolledModal}
          closeModal={this.closeNotEnrolledModal}
        />
        {this.state.showBeneficiaryAddressNotice && this.renderBeneficiaryAddressNotice()}
        {!this.state.renderError && (
          <div className="sign">
            <button
              className={`nav-review__button doc__action--stop ${
                this.state.showMobileNav ? 'hidden' : ''
              }`}
              onClick={this.toggleNavigation}
              onKeyDown={e => onSpaceOrEnterKeyDown(e, this.toggleNavigation)}
              aria-expanded={Boolean(this.state.showMobileNav)}
              aria-label="Document navigation menu"
              type="button"
            >
              <span className="long" />
              <span className="short" />
              <span className="short" />
              <span className="long" />
            </button>
            <div
              id="review__id"
              className={containerClasses}
              tabIndex="0"
              dangerouslySetInnerHTML={this.state.docText}
            />
            {this.renderActionButtons()}
          </div>
        )}
        {this.renderDocumentNavigation()}
        {activePerson && (
          <DocumentModal
            activePerson={activePerson}
            reviewNode={this.reviewNode}
            toggleDocumentPopUp={this.toggleDocumentPopUp}
          />
        )}
        {documentPopUp.active && (
          <DocumentPopUp
            type={documentPopUp.type}
            onCloseClick={() => this.toggleDocumentPopUp()}
            onCancel={() => this.toggleDocumentPopUp()}
            goToCheckList={this.goToReplacePage}
            onUpdatePerson={this.handleUpdatePersonName}
            personData={documentPopUp.data}
            isUpdatingAction={isUpdatingAction}
          />
        )}
      </div>
    );
  }
}

export default withSnackbar(Review);
