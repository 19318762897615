import deepEqual from 'deep-equal';
import { API } from 'API';
import numeral from 'numeral';
import { DocumentBundle, SectionType } from 'utils/enums';

// A minimalist implementation for subscribing to an event
//   (an instance of Callbacks) that gets fired. The list
//   of callbacks are stored in the component that creates
//   the Callbacks instance. This allows other components
//   to react to changes if the list of callbacks is
//   changed.
// An example of this is dynamically updating the validity
//   of a form based on the fields that are displayed. Adding
//   a new field (and hence, a validation callback) changes
//   the form validity because different validations must
//   be run.
export class Callbacks {
  constructor(component, stateKey) {
    this.stateKey = stateKey;
    this.component = component;
  }

  get callbacks() {
    return this.component.state[this.stateKey];
  }

  setCallbacks(modifier) {
    // Since `setState` is not synchronous, multiple changes can
    //   be applied at the same time. This could result in the
    //   values of the array not matching the desired changes. To
    //   remedy this, we pass a callback to `setState`, which
    //   allows us to get the current state that will be modified
    //   at the time of modification instead of the current state
    //   when `setState` is called.
    this.component.setState(state => ({
      ...state,
      [this.stateKey]: modifier([...state[this.stateKey]]),
    }));
  }

  subscribe(cb) {
    this.setCallbacks(callbacks => [...callbacks, cb]);
  }

  unsubscribe(cb) {
    this.setCallbacks(callbacks => {
      for (let i = 0; i < callbacks.length; i++) {
        if (callbacks[i] === cb) {
          callbacks.splice(i, 1);
          break;
        }
      }

      return callbacks;
    });
  }

  emit(event) {
    for (const cb of this.callbacks) {
      cb(event);
    }
  }
}

export function joinClass(...classNames) {
  return classNames.filter(name => name).join(' ');
}

export function andList(list) {
  if (list.length > 2) {
    return list.slice(0, -1).join(', ') + ', and ' + list[list.length - 1];
  } else if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  } else {
    return list[0];
  }
}

export const requiresBeneficiariesAddressStates = ['CO', 'FL', 'IL', 'IN', 'MO', 'TX', 'VA'];

export const requiresBeneficiariesRelationshipStates = ['CA'];

export const requiresHealthcareAgentAddressStates = [
  'IN',
  'KS',
  'NC',
  'NH',
  'OH',
  'SC',
  'TX',
  'UT',
];

export const requiresFelonExemptionStates = ['AL', 'AR', 'DE', 'DC', 'IL', 'IN'];

export const requiresResidentExecutorStates = ['FL'];

export const statesThatProhibitAHDs = ['OR'];

export const requiresOmbudsmanStates = ['CA'];

// enable digital execution
const canNotarizeOnlineStates = [
  'CA',
  'TX',
  'MN',
  'KS',
  'NH',
  'AR',
  'NM',
  'ID',
  'WY',
  'NJ',
  'UT',
  'MA',
  'NV',
  'AL',
  'RI',
  'ND',
  'SD',
  'SC',
  'WI',
  'MT',
  'CO',
  'NE',
  'IL',
  'WA',
  'DC',
  'AZ',
];

const canProofNotarizeStates = ['NH', 'IL', 'MN'];

const noDigexCounties = [
  'stanislaus',
  'san francisco',
  'santa barbara',
  'sutter',
  'dekalb',
  'peoria',
  'dewitt',
  'sumner',
  'tulare',
  'mendocino',
  'el dorado',
  'sierra',
  'saline',
  'tuolumne',
  'runnels',
  'dakota',
  'hudspeth',
  'mcculloch',
  'benton',
];

// All states currently use drawn signatures.
export const multipleChoiceSignatureStates = [];

export const realEstateOwnedByBothStates = ['CA'];

// A place to add custom options later, also help with importing.
export function deepEquals(a, b) {
  return deepEqual(a, b);
}

export function formatMoney(val) {
  return numeral(val).format('$0,0[.]00');
}

export function getEmail() {
  return 'wills@legalplans.com';
}

export function getPhoneNumber(clientData, serverData, format = 'normal') {
  let number;
  const willingPhoneNumber = '(855) 856-1381';
  const covidPhoneNumber = '(855) 959-2700';
  const mlpPhoneNumber = '(833) 946-2005';
  const depByDefaultPhoneNumber = '(855) 959-2700';

  if (serverData.depByDefault) {
    number = depByDefaultPhoneNumber;
  } else if (clientData.covidEmployer === 'upwise') {
    number = mlpPhoneNumber;
  } else if (serverData.fromPortunus) {
    number = mlpPhoneNumber;
  } else if (clientData.covidEmployer) {
    number = covidPhoneNumber;
  } else {
    number = willingPhoneNumber;
  }

  if (format === 'display') {
    // convert "(888) 888-888" to "888 - 888 - 8888"
    return number.replace(/[()]/g, '').replace(' ', '-').replace('-', ' - ');
  }
  if (format === 'link') {
    return 'tel:' + number.replace(/[() -]/g, '');
  }
  return number;
}

export function getCompanyName(serverData, format = 'short') {
  const willingNames = { short: 'Willing', normal: 'Willing, Inc.', owner: 'MetLife Legal Plans' };
  const mlpNames = {
    short: 'MetLife',
    normal: 'MetLife Legal Plans',
    owner: 'MetLife Legal Plans',
  };
  if (serverData.fromPortunus) {
    return mlpNames[format];
  } else {
    return willingNames[format];
  }
}

const ssnPattern = /^\d{4}$/;

export function validateSSN(ssn) {
  if (ssnPattern.test(ssn)) {
    return [];
  }
  return ['Must be four digits.'];
}

export const namePattern = /^[A-Z]\S+(\s[A-Z]\S*)+$/;

export function testName(name) {
  return namePattern.test(name) && !name.toLowerCase().includes(' or ');
}

export function guard(f) {
  try {
    f();
  } catch (e) {
    // Don't do anything. We know this can fail.
  }
}

export const noOp = () => null;

export function pad(s, length, char = '0') {
  s = s ? s.toString() : '';
  length = length - s.length > 0 ? length - s.length : 0;
  return char.repeat(length) + s;
}

export function changeName(clientData, newName, oldUUID) {
  clientData.walkPeople(null, person => {
    if (person.personUUID === oldUUID) {
      person.setRawValue('name', newName);
    }
  });
}

export async function fetchDocumentTypes(documentBundle) {
  const documentsInfo = await API.getDocumentsInfo(documentBundle);
  const documents = [];
  for (const section of documentsInfo.sections) {
    for (const doc of section.documents) {
      documents.push(doc.document_type);
    }
  }
  return documents;
}

export function pascalCaseToSnakeCase(str) {
  if (str.length === 0) {
    return '';
  }

  if (str.toUpperCase() === str) {
    // All upper case, most likely an abbreviation
    return str.toLowerCase();
  }

  let result = str[0].toLowerCase();
  for (let i = 1; i < str.length; i++) {
    if (str[i].toUpperCase() === str[i]) {
      result += '_' + str[i].toLowerCase();
    } else {
      result += str[i];
    }
  }
  return result;
}

export function pascalCaseToSnakeCaseObject(obj) {
  const result = {};
  for (const [key, value] of Object.entries(obj)) {
    result[pascalCaseToSnakeCase(key)] = value;
  }
  return result;
}

export function getPersonNameByUUID(clientData, uuid) {
  let fullName = '';

  clientData.walkPeople(null, person => {
    if (person.personUUID === uuid) {
      fullName = person.name;
    }
  });

  return { fullName, firstName: fullName.split(' ')[0] };
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function getDocumentBundleLevel(documentBundle) {
  switch (documentBundle) {
    case DocumentBundle.SINGLE:
      return 1;
    case DocumentBundle.COUPLES:
      return 2;
    case DocumentBundle.HOMEOWNERS:
      return 3;
    case DocumentBundle.ULTIMATE:
      return 4;
    default:
      return 0;
  }
}

export function documentBundleIncludes(documentBundle, otherDocumentBundle) {
  return getDocumentBundleLevel(documentBundle) >= getDocumentBundleLevel(otherDocumentBundle);
}

export function canNotarizeOnline(clientData, serverData) {
  if (clientData.covidEmployer || !serverData.fromPortunus) {
    return false;
  }

  if (clientData.realEstates.some(a => noDigexCounties.includes(a.address.county.toLowerCase()))) {
    return false;
  }

  return (
    canNotarizeOnlineStates.includes(clientData.address.state) &&
    !clientData.outOfStateUnlockedRealEstates.length
  );
}

export function canProofNotarize(clientData, serverData) {
  return (
    canNotarizeOnline(clientData, serverData) &&
    canProofNotarizeStates.includes(clientData.address.state)
  );
}

export function getAgent(list, type, owner) {
  return list.find(a => a.type === type && a.owner === owner) || null;
}

export function setAgent(list, type, owner, agent) {
  for (const [i, e] of list.entries()) {
    if (e.type === type && e.owner === owner) {
      if (agent) {
        list[i] = agent;
      } else {
        list.splice(i, 1);
      }
      return;
    }
  }
  if (agent) {
    list.push(agent);
  }
}

export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export function getStateFullName(abbr) {
  return STATES[abbr] || abbr;
}

export function getStateAbbreviation(fullNameOrAbbreviation) {
  return (
    Object.keys(STATES).find(
      abbr => STATES[abbr].toLowerCase() === fullNameOrAbbreviation.toLowerCase(),
    ) || fullNameOrAbbreviation
  );
}

export const TOD_STATES = [
  'AR',
  'AZ',
  'CA',
  'CO',
  'FL',
  'IL',
  'IN',
  'KS',
  'MN',
  'MO',
  'NE',
  'NV',
  'OH',
  'OK',
  'OR',
  'TX',
  'VA',
  'WA',
  'WI',
];

export const refresh = () => {
  window.onbeforeunload = null;
  window.location.reload(true);
};

export const getSectionText = (sectionType, clientData) => {
  switch (sectionType) {
    case SectionType.CLIENT:
      if (clientData.isPlanningForSpouse) {
        return `${clientData.firstName}'s Documents`;
      }
      return 'Documents';
    case SectionType.SPOUSE:
      return `${clientData.spouse.firstName}'s Documents`;
    case SectionType.JOINT:
      return 'Joint Documents';
    default:
      return '';
  }
};

export const base64ToBlob = (base64, blobOptions) => {
  const defaultOptions = {
    type: 'application/pdf',
  };
  const options = Object.assign(defaultOptions, blobOptions || {});
  const byteString = window.atob(base64);
  const bytes = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; ++i) {
    bytes[i] = byteString.charCodeAt(i);
  }

  return new Blob([bytes], options);
};

export const gaEvent = async (category, action) => {
  if (window.gtag) {
    window.gtag('event', action, {
      event_category: category,
    });
  }
};

export const onSpaceOrEnterKeyDown = (e, callback) => {
  if (e.key === 'Enter' || e.key === ' ') {
    callback();
  }
};
