import React from 'react';
import PropTypes from 'prop-types';
import Radio from '@material-ui/core/Radio';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MuiRadioGroup from '@material-ui/core/RadioGroup';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel } from '@material-ui/core';

const defaultOptions = [
  [true, 'Yes'],
  [false, 'No'],
];

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 14,
  },

  labelRoot: {
    margin: 0,
  },

  optionRoot: {
    position: 'relative',
    // Styles grabbed from the MUI Button Component
    boxSizing: 'border-box',
    minWidth: 64,
    padding: '12px 30px',
    letterSpacing: '0.5px',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid #D5DFE8',
    color: theme.palette.text.primary,
    backgroundColor: '#fff',
    boxShadow: 'none',
    '&$hasValue': {
      backgroundColor: theme.palette.background.default,
      boxShadow: 'inset 0 1px 3px 0 rgba(162, 162, 162, 0.5)',
    },
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: fade(theme.palette.text.primary, theme.palette.action.hoverOpacity),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '&$disabled': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-focusVisible': {
      borderColor: theme.palette.primary.main,
    },
    '&$disabled': {
      color: theme.palette.action.disabled,
      border: `1px solid ${theme.palette.action.disabledBackground}`,
    },
    '&.Mui-checked': {
      padding: '14px 32px',
      letterSpacing: '0.89px',
      fontWeight: 600,
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
      border: 0,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: theme.shadows[4],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: theme.shadows[2],
          backgroundColor: theme.palette.primary.main,
        },
        '&$disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
      '&:focus': {
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.dark,
      },
      '&.Mui-focusVisible': {
        outline: '5px auto Highlight',
        // eslint-disable-next-line no-dupe-keys
        outline: '5px auto -webkit-focus-ring-color',
        outlineOffset: '4px',
      },
      '&$disabled': {
        color: theme.palette.action.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
      },
    },
  },
  /* Pseudo-class applied to the root element if the pulse animation should be on. */
  pulse: {
    '&:not($hasValue)': {
      animation: '$pulse 3s infinite ease-in-out',
    },
  },

  '@keyframes pulse': {
    '50%': {
      boxShadow: '0 5px 25px 1px rgba(0, 0, 0, 0.28)',
    },
  },
  optionLabel: {
    width: '100%', // Ensure the correct width for iOS Safari
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
    fontSize: '16px',
    lineHeight: 1,
    textTransform: 'none',
    color: 'inherit',
    letterSpacing: 'inherit',
    fontWeight: 'inherit',
  },
  /* Pseudo-class applied to the ButtonBase root element if the button is keyboard focused. */
  focusVisible: {},
  /* Pseudo-class applied to the root element if `disabled={true}`. */
  disabled: {},
  /* Pseudo-class applied to the root element if radio is checked. */
  checked: {},
  /* Pseudo-class applied to the root element if radio has any non-empty value. */
  hasValue: {},
});

class ButtonRadioGroup extends React.Component {
  get hasValue() {
    const { value } = this.props;
    return value !== null && value !== '';
  }

  selectValue = value => () => {
    const { onChange } = this.props;
    // Convert value to an actual boolean if the radio button returns true or false as a string.
    if (
      typeof value === 'string' &&
      (value.toLowerCase() === 'true' || value.toLowerCase() === 'false')
    ) {
      value = value === 'true';
    }
    onChange(value);
  };

  valueChanged = event => {
    this.selectValue(event.target.value)();
  };

  // Render one of the radio buttons.
  renderOption = ([value, displayText], pulse, rest) => {
    const { classes, disabled, value: selectedValue, label: labelText } = this.props;
    const selected =
      typeof selectedValue === 'string' && this.hasValue
        ? String(value) === selectedValue
        : value === selectedValue;

    const optionsClasses = classNames({
      [classes.pulse]: pulse,
      [classes.hasValue]: this.hasValue,
    });
    const simpleLabelIcon = <span className={classes.optionLabel}>{displayText}</span>;

    return (
      <Grid item key={value}>
        <FormControlLabel
          value={String(value)}
          classes={{
            root: classes.labelRoot,
          }}
          control={
            <Radio
              {...rest}
              disabled={disabled}
              checked={selected}
              onChange={this.valueChanged}
              data-value={value}
              color="primary"
              inputProps={{ title: labelText }}
              label={displayText}
              icon={simpleLabelIcon}
              checkedIcon={simpleLabelIcon}
              classes={{
                root: classes.optionRoot,
              }}
              className={optionsClasses}
            />
          }
        ></FormControlLabel>
      </Grid>
    );
  };

  render() {
    const { label, value, classes, options, pulseFirstOption, ...rest } = this.props;

    const { className: externalClassName, ...optionProps } = rest;

    const renderedOptions = options.map((opt, idx) =>
      this.renderOption(opt, pulseFirstOption & !idx, optionProps),
    );

    return (
      <>
        {label && (
          <Typography id={label} variant="body1">
            {label}
          </Typography>
        )}
        <MuiRadioGroup
          role="radiogroup"
          name={label}
          aria-labelledby={label}
          value={String(value)}
          classes={{
            root: classes.root,
          }}
        >
          <Grid container spacing={2}>
            {renderedOptions}
          </Grid>
        </MuiRadioGroup>
      </>
    );
  }
}

ButtonRadioGroup.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  pulseFirstOption: PropTypes.bool,

  /** @ignore */
  classes: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.array),
};

ButtonRadioGroup.defaultProps = {
  label: null,
  disabled: false,
  onChange: () => {},
  pulseFirstOption: false,
  value: null,
  options: defaultOptions,
};

export default withStyles(styles)(ButtonRadioGroup);
