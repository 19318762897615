import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Tunnel } from 'react-tunnels';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

import { titleCase } from '@willing-shared/utils/text';

import CheckSelected from '@a/images/check-selected.svg';
import CheckDeselected from '@a/images/check-deselected.svg';
import BreadcrumbArrow from '@a/images/breadcrumb-arrow.svg';

function addIds(sections) {
  Object.entries(sections).forEach(([k, v]) => {
    v.id = k.toLowerCase();
  });

  return sections;
}

export const ProgressSections = Object.freeze(
  addIds({
    ABOUT_YOU: {
      BASIC_INFO: 'basic_info',
      FAMILY: 'family',
      REAL_ESTATE: 'real_estate',
    },
    YOUR_WISHES: {
      GUARDIANS: 'guardians',
      ASSETS: 'assets',
      EXECUTORS: 'executor',
      HEALTHCARE: 'healthcare',
    },
    DOCUMENTS: {},
  }),
);

const styles = theme => ({
  root: {
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    margin: [[0, 'auto']],
    flexDirection: 'column',

    '&$expanded': {
      maxWidth: 380,
      marginBottom: 24,
    },

    [theme.breakpoints.up('lg')]: {
      maxWidth: '100%',
      marginBottom: 48,
    },
  },
  expanded: {
    // This is used to toggle styles in
    //   other classes.
  },
  mobileRow: {
    justifyContent: 'space-between',
    margin: [[0, theme.layout.side.default]],

    [theme.breakpoints.up('md')]: {
      margin: 0,
    },
  },
  sections: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileItem: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  section: {
    flexGrow: 0,
    display: 'inline-block',
    color: theme.palette.custom.unselected,
  },
  arrow: {
    height: 8,

    [theme.breakpoints.up('md')]: {
      height: 16,
    },
  },
  selectedSection: {
    color: '#fff',
    borderRadius: 5,
    padding: [[2, 6]],
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.up('md')]: {
      padding: [[3, 8]],
    },
  },
  toggle: {
    marginBottom: 8,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-block',
    color: theme.palette.text.secondary,
    marginLeft: theme.layout.side.default,

    '& img': {
      height: 8,
      marginLeft: 8,
    },
  },
  bar: {
    height: 5,
    borderRadius: 2,
    overflow: 'hidden',
    background: theme.palette.custom.border,

    '$root$expanded &': {
      margin: [[8, 0]],
    },

    [theme.breakpoints.up('lg')]: {
      margin: [[8, 0]],
    },
  },
  barFill: {
    height: '100%',
    background: theme.gradients.special,
  },
  pages: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      marginLeft: 8,
      marginRight: 2,
    },
    '& img:first-child': {
      marginLeft: 0,
    },
  },
  pageWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  page: {
    flexGrow: 0,
    display: 'inline-block',
  },
});

class Progress extends React.Component {
  static propTypes = {
    page: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    section: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
  };

  state = {
    expanded: false,
  };

  get mobileMode() {
    if (this.state.expanded) {
      return false;
    }

    return this.isLaptopOrSmallerDisplay;
  }

  keyToText(key) {
    return titleCase(key.replace('_', ' '));
  }

  renderSections() {
    if (this.mobileMode) {
      return null;
    }

    const { classes } = this.props;

    const sections = Object.entries(ProgressSections).map(([k, v], i, array) => {
      const selected = v.id === this.props.section.id;
      const last = i === array.length - 1;

      const className = classNames(classes.section, classes.mobileItem, {
        [classes.selectedSection]: selected,
      });

      return (
        <React.Fragment key={k}>
          <Typography component="span" className={className} variant={selected ? 'body1' : 'body2'}>
            {this.keyToText(k)}
          </Typography>

          {!last && <img alt="" src={BreadcrumbArrow} className={classes.arrow} />}
        </React.Fragment>
      );
    });

    return <div className={classNames(classes.sections, classes.mobileRow)}>{sections}</div>;
  }

  renderBar() {
    const { classes, page, section } = this.props;
    const sectionEntries = Object.entries(section).filter(([k]) => k !== 'id');

    // The bar never shows as completely empty,
    //   and never shows as completely full.
    //   This is the total padding on each side
    //   that will not be available for actual
    //   status.
    const unusedPercent = 12;

    const completePages = sectionEntries.findIndex(e => e[1] === page);
    const percentPerPage = (100 - unusedPercent) / sectionEntries.length.toFixed(2);
    const percentComplete = unusedPercent / 2 + completePages * percentPerPage;

    return (
      <div
        className={classes.bar}
        role="progressbar"
        aria-valuenow={Math.trunc(percentComplete)}
        aria-label="Progress"
      >
        <div className={classes.barFill} style={{ width: `${percentComplete}%` }} />
      </div>
    );
  }

  renderPages() {
    if (this.mobileMode) {
      return null;
    }

    const { classes, page, section } = this.props;
    const pageEntries = Object.entries(section);
    const selectedIndex = pageEntries.findIndex(e => e[1] === page);

    const pages = pageEntries.map(([k], i) => {
      if (k === 'id') {
        return null;
      }

      const checked = i <= selectedIndex;
      const checkSource = checked ? CheckSelected : CheckDeselected;

      return (
        <span key={k} className={classes.pageWrapper}>
          <img src={checkSource} alt="" />

          <Typography
            variant="body1"
            component="span"
            className={classNames(classes.page, classes.mobileItem)}
          >
            {this.keyToText(k)}
          </Typography>
        </span>
      );
    });

    return <div className={classNames(classes.pages, classes.mobileRow)}>{pages}</div>;
  }

  render() {
    const { classes } = this.props;
    const collapse = () => this.setState({ expanded: false });

    const className = classNames(classes.root, {
      [classes.expanded]: this.state.expanded,
    });

    return (
      <Tunnel id="progress">
        <ClickAwayListener onClickAway={collapse}>
          <div className={className}>
            {this.renderSections()}
            {this.renderBar()}
            {this.renderPages()}
          </div>
        </ClickAwayListener>
      </Tunnel>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(Progress));
