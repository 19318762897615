import React from 'react';
import PropTypes from 'prop-types';

import Layout from '@c/layout/Layout';
import { Tip, Tips } from '@c/tips';
import FieldGroup from './FieldGroup';
import StateSelector from '@c/StateSelector';
import BoundNameField from '@c/BoundNameField';
import BoundTextField from '@c/BoundTextField';
import BoundRadioGroup from '@c/BoundRadioGroup';
import { ProgressSections } from '@c/progress';
import { AppLocation } from 'utils/enums';
import { MaritalStatus } from '!m/client-data/enums';
import { validatePersonNameUniqueness } from '!m/utils';
import { getStateAbbreviation } from 'utils';
import SlideShow from '@wui/basics/slideShow';
import withHandleNameConflicts from 'hocs/withHandleNameConflicts';

import loaderSecurity from '@a/images/loader-security.gif';
import loaderLegal from '@a/images/loader-legal.gif';
import loaderDocument from '@a/images/loader-document.gif';
import loaderSuccess from '@a/images/loader-success.gif';

import MaritalStatusTip from './MaritalStatusTip';

class PersonalDetails extends React.Component {
  static contextTypes = {
    testator: PropTypes.object,
    experiments: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  static propTypes = {
    handleNameConflicts: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = { showAnimation: this.firstTime };
  }

  componentDidMount() {
    // when passed certain parameters, update clientData so that input elements will use them as default values
    const prefillParams = {
      state: [this.context.testator.address, 'state', getStateAbbreviation],
      relationship: [this.context.testator, 'maritalStatus', v => MaritalStatus.normalize(v)],
    };
    const params = new URLSearchParams(window.location.search);
    Object.entries(prefillParams).forEach(([name, [object, parameter, normalize]]) => {
      if (params.get(name)) {
        object.setRawValue(parameter, normalize(params.get(name)));
      }
    });

    this.context.clientDataHolder.analyticsEvents.dataLayer.push({
      event: 'willing.page.loaded.personal-details',
    });
  }

  nameValidations() {
    return [
      validatePersonNameUniqueness(this.context.clientDataHolder.clientData, this.context.testator),
    ];
  }

  get firstTime() {
    return !this.context.testator.name;
  }

  renderFirstTimeTip() {
    if (!this.firstTime) {
      return null;
    }

    return (
      <Tip title="Welcome to Your Estate Plan!">
        You have officially started your plan. All of your information is secure and confidential.
        This won't take long, and you'll be very glad you did it.
      </Tip>
    );
  }

  hideAnimation = () => {
    this.setState({ showAnimation: false });
  };

  renderContent() {
    // show an animation to start
    if (this.state.showAnimation && this.context.experiments.aboutYouAnimation) {
      const slideData = [
        { text: 'Creating Secure Session', image: loaderSecurity },
        { text: 'Loading Legal Library', image: loaderLegal },
        { text: 'Starting Document Builder', image: loaderDocument },
        { text: 'Session Ready', image: loaderSuccess, duration: 2000 },
      ];
      return (
        <div onClick={this.hideAnimation}>
          <SlideShow slides={slideData} complete={this.hideAnimation} />
        </div>
      );
    }

    const { handleNameConflicts } = this.props;

    // show the personal details form after the animation is done
    return (
      <FieldGroup level={1} title="About You">
        <BoundNameField
          label="Full Name"
          onBlur={handleNameConflicts(this.context.testator)}
          additionalValidations={this.nameValidations()}
        />
        <StateSelector
          dataPathOverride="address.state"
          addressObject={this.context.testator.address}
        />
        {!this.context.experiments.delayedMaritalStatus && (
          <BoundTextField path="maritalStatus" label="Marital Status" />
        )}
        {this.context.experiments.incomeRange && (
          <BoundTextField path="annualIncome" label="Average Annual Income" />
        )}
        {this.context.experiments.lifeInsuranceStatus && (
          <BoundRadioGroup path="hasLifeInsurance" label="Do you currently own life insurance?" />
        )}
      </FieldGroup>
    );
  }

  render() {
    // hide the "next" button during the animation
    const buttonRenderer =
      this.state.showAnimation && this.context.experiments.aboutYouAnimation ? () => null : null;
    return (
      <Layout
        buttonRenderer={buttonRenderer}
        progressPage={ProgressSections.ABOUT_YOU.BASIC_INFO}
        appLocation={AppLocation.ABOUT_YOU}
      >
        <Tips>
          {this.renderFirstTimeTip()}
          <Tip title="State">
            All documents are based on current state laws.
            <br />
            <br />
            Enter the state of your primary residence. If you own property in different states,
            we'll address that later on.
          </Tip>

          {!this.context.experiments.delayedMaritalStatus && <MaritalStatusTip />}
        </Tips>

        {this.renderContent()}
      </Layout>
    );
  }
}

export default withHandleNameConflicts(PersonalDetails);
