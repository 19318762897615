import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import { formatMoney, joinClass, getCompanyName, getEmail } from 'utils';
import { DocumentBundle } from 'utils/enums';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import {
  CardCVCElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';
import { API } from 'API';
import PureButton from 'ui/components/pure-controls/PureButton';
import CheckoutFooter from 'ui/components/payment/CheckoutFooter';
import SubscriptionPicker from 'ui/SubscriptionPicker';
import CheckoutSuccess from '@/pages/Checkout/Success';

import './CheckoutForm.scss';
import sentry from '@willing-shared/utils/sentry';

export const CheckoutForm = injectStripe(
  class CheckoutForm extends ClientDataReferer {
    static propTypes = {
      documentBundle: PropTypes.string.isRequired,
      subscriptionPlan: PropTypes.string,
      updatePriceInfo: PropTypes.func,
      setPaid: PropTypes.func,
    };

    static defaultProps = {
      updatePriceInfo: () => {},
      documentBundle: '',
      subscriptionPlan: '',
    };

    constructor(props, context) {
      super(props, context);

      this.state = {
        couponCode: '',
        mode: 'pay',
        showPromo: false,
        isPaymentPending: false,
        cardError: '',
        expirationError: '',
        cvcError: '',
        couponError: '',
        couponSuccess: '',
        brand: 'unknown',
        grouponDocumentBundle: null,
        canUsePaymentRequest: false,
        paymentRequest: {},
        loaded: false,
      };

      // Enabling the experiment can take a little time, so
      //   add a small delay to the redirect rendering.
      setTimeout(() => this.setState({ loaded: true }), this.context.experiments.delay);

      this.analyticsEvents.dataLayer.push({
        event: 'willing.page.loaded.checkout-form',
      });

      this.cardBrandToClass = {
        visa: 'cc-visa',
        mastercard: 'cc-mastercard',
        amex: 'cc-american-express',
        discover: 'cc-discover',
        unknown: 'cc-credit-card',
      };

      const locationState = this.clientDataHolder.props.location.state || {
        includedRealEstate: this.clientData.realEstates.map(a => a.assetUUID),
      };
      this.includedRealEstate = locationState.includedRealEstate || [];
    }

    componentDidUpdate = prevProps => {
      const changedProduct =
        this.props.documentBundle !== prevProps.documentBundle ||
        this.props.subscriptionPlan !== prevProps.subscriptionPlan;

      if (changedProduct) {
        API.getPriceInfo(
          this.state.couponCode,
          this.props.documentBundle,
          this.props.subscriptionPlan,
          this.includedRealEstate,
        ).then(({ discountedPrice }) => {
          this.setState({ price: discountedPrice / 100 });
        });
      }
    };

    get subscriptionOnly() {
      return this.props.subscriptionPlan && !this.props.documentBundle;
    }

    applyCoupon = async () => {
      const data = await API.applyCoupon(
        this.state.couponCode,
        this.props.documentBundle,
        this.props.subscriptionPlan,
        this.includedRealEstate,
      );
      const { grouponDocumentBundle, couponDiscount } = data;

      if (this.state.price === data.discountedPrice / 100) {
        this.setState({
          couponError: 'Gift code is invalid',
          couponSuccess: '',
        });
      } else {
        this.props.updatePriceInfo(data);
        const companyName = getCompanyName(this.serverData);
        this.state.paymentRequest.update({
          total: {
            label: `${companyName} ${this.props.documentBundle} estate planning bundle`,
            amount: data.discountedPrice,
          },
        });
        this.setState({
          price: data.discountedPrice / 100,
          couponError: '',
          couponSuccess: 'Successfully applied!',
        });
      }

      if (data.discountedPrice / 100 < 1) {
        if (grouponDocumentBundle) {
          this.props.updatePriceInfo(grouponDocumentBundle, couponDiscount);
        }

        this.analyticsEvents.dispatchEvent('redeemedCoupon');
        await this.bundlePaymentSuccess(null, {
          grouponDocumentBundle,
          couponError: '',
        });
      }
    };

    sendPaymentAnalytics = response => {
      this.analyticsEvents.dispatchEvent('completedPayment', response);
      if (response.couponDiscount > 0) {
        this.analyticsEvents.dispatchEvent('redeemedCoupon');
      }
    };

    getStripeSource = async e => {
      e.preventDefault();
      if (this.state.isPaymentPending) {
        return;
      }
      this.setState({ isPaymentPending: true });

      const source = await this.props.stripe.createSource({
        owner: {
          name: this.clientData.name || this.clientData.email,
        },
      });

      this.handleSubmit(source.source);
    };

    handleSubmit = async source => {
      if (!source) {
        this.setState({ mode: 'failure', isPaymentPending: false });
        return false;
      }

      let response;

      if (this.subscriptionOnly) {
        try {
          response = await API.updateStripeSubscription(this.props.subscriptionPlan, source.id);
        } catch (ex) {
          this.setState({ mode: 'failure', isPaymentPending: false });
          return false;
        }

        this.clientDataHolder.analyticsEvents.subscriptionCreated(response);
        this.setState({ mode: 'success', isPaymentPending: false });
        await this.clientDataHolder.persistClientData();
        return true;
      }

      try {
        response = await API.checkout(
          source.id,
          this.props.documentBundle,
          this.state.couponCode,
          this.props.subscriptionPlan,
          this.includedRealEstate,
        );
      } catch (ex) {
        this.setState({ mode: 'failure', isPaymentPending: false });
        return false;
      }

      try {
        const { grouponDocumentBundle } = response;
        if (grouponDocumentBundle) {
          this.props.updatePriceInfo(grouponDocumentBundle, response.discountedPrice);
        }

        await this.bundlePaymentSuccess(response, {
          grouponDocumentBundle,
        });
      } catch (ex) {
        sentry.captureException(ex);
      }

      return true;
    };

    async bundlePaymentSuccess(response = null, additionalState = null) {
      if (!additionalState) {
        additionalState = {};
      }

      if (response) {
        this.sendPaymentAnalytics(response);
      }

      this.analyticsEvents.dataLayer.push({
        event: 'willing.trigger.subscription-version-selection',
      });

      this.props.setPaid();
      await this.clientDataHolder.persistClientData();

      setTimeout(
        () =>
          this.setState({
            mode: 'success',
            isPaymentPending: false,
            ...additionalState,
          }),
        this.context.experiments.delay,
      );
    }

    renderFailure() {
      return (
        <div className="checkout-form">
          <h4 className="center">Your payment did not go through</h4>
          <p className="center small subtext">
            Sorry about that.
            <br />
            Try again or use a different card.
          </p>
          <PureButton
            onClick={() =>
              this.setState({
                mode: 'pay',
                isPaymentPending: false,
              })
            }
            className="button--primary button--finalize center"
          >
            Try again
          </PureButton>
        </div>
      );
    }

    renderSuccess() {
      const goToSubscriptions = !this.serverData.subscriptionPlan;

      if (goToSubscriptions && !this.subscriptionOnly) {
        return (
          <Redirect
            push
            to={{
              pathname: CheckoutSuccess.url,
            }}
          />
        );
      }

      let documentBundleDescription;
      if (this.state.grouponDocumentBundle === DocumentBundle.SINGLE) {
        documentBundleDescription = 'an Individuals';
      } else if (this.state.grouponDocumentBundle === DocumentBundle.COUPLES) {
        documentBundleDescription = 'a Married Couples';
      } else if (this.state.grouponDocumentBundle === DocumentBundle.HOMEOWNERS) {
        documentBundleDescription = 'a Home Owners';
      } else if (this.state.grouponDocumentBundle) {
        throw `Unknown document bundle: ${this.state.grouponDocumentBundle}`;
      }

      const planComplete = this.clientDataHolder.allPlanSectionsValid();

      const successButtonUrl = goToSubscriptions
        ? SubscriptionPicker.url
        : this.clientDataHolder.dashboardUrl;
      const successSubtext = goToSubscriptions
        ? 'choose how you want to finish everything up.'
        : planComplete
        ? 'get your documents signed and notarized.'
        : 'finish your plan.';
      const successButtonText = goToSubscriptions
        ? 'Continue'
        : planComplete
        ? 'Sign and Notarize My Documents'
        : 'Complete My Plan';

      return (
        <div className="checkout-form">
          <h4 className="center">Thank you!</h4>
          <p className="center small subtext">
            {this.state.grouponDocumentBundle && (
              <span>
                Your Groupon for {documentBundleDescription} document bundle has been redeemed.
                <br />
              </span>
            )}
            Your family will really appreciate it.
            <br />
            Now let's {successSubtext}
          </p>
          <Link to={successButtonUrl}>
            <button className="button button--primary button--finalize center" id="paymentComplete">
              {successButtonText}
            </button>
          </Link>
        </div>
      );
    }

    renderGenericHeader = info => (
      <div className="form__header">
        <div className="checkout-form__description">
          <h5>{info.heading}</h5>
        </div>
        <div className="pricebox">
          <p>{info.description}</p>
          <div className="pricebox_total">
            <div className="pricebox__label">Total Price</div>
            <div className="pricebox__price">{formatMoney(this.state.price)}</div>
          </div>
        </div>
      </div>
    );

    renderHeader = () => {
      const ownerString = this.clientData.isPlanningForSpouse
        ? 'for you and your spouse'
        : 'for your complete set of documents';
      const estateInfo = {
        heading: 'Estate Planning Bundle',
        description: `One flat price ${ownerString}.`,
      };

      switch (this.props.documentBundle) {
        case DocumentBundle.SINGLE:
          return this.renderGenericHeader({
            heading: 'Individuals',
            description: "The right choice when you're planning only for yourself.",
          });
        case DocumentBundle.COUPLES:
          return this.renderGenericHeader({
            heading: 'Married couples',
            description: "The perfect option for married couples who don't own a home.",
          });
        case DocumentBundle.HOMEOWNERS:
          return this.renderGenericHeader({
            heading: 'Homeowners',
            description: 'Our most complete plan for people who own a home.',
          });
        default:
          return this.renderGenericHeader(estateInfo);
      }
    };

    setError = (e, path) => {
      if (e.error) {
        this.setState({ [path]: e.error.message });
      } else {
        this.setState({ [path]: '' });
      }
    };

    onChangeCardField = (e, type) => {
      const errorType = `${type}Error`;
      const { complete } = e;

      switch (type) {
        case 'card':
          if (e.brand) {
            this.setState({ brand: e.brand });
          }
          if (complete) {
            this.cardExpiry._ref.click();
          }
          break;
        case 'expiration':
          if (complete) {
            this.cardCVC._ref.click();
          }
          break;
        default:
          return;
      }
      this.setError(e, errorType);
    };

    renderSupport() {
      const email = getEmail();
      return (
        <div className="contact-info">
          Questions? Email us at{' '}
          <a href={`mailto:${email}`} aria-label={`${email} email`}>
            {email}
          </a>
        </div>
      );
    }

    get paymentRequestButtonClass() {
      return `button--fullwidth button--payment-request ${
        isIOS ? 'button--apple-pay' : 'button--google-pay'
      }`;
    }

    renderPay() {
      const stripeStyles = {
        base: {
          fontFamily: 'Verdana, sans-serif',
          fontSize: '24px',
          fontWeight: 'normal',
          color: '#595959',
          '::placeholder': {
            color: '#A0A0A0',
            fontWeight: '100',
          },
        },
        invalid: {
          color: '#595959',
        },
      };

      const errors = [this.state.cardError, this.state.expirationError, this.state.cvcError].filter(
        i => i,
      );

      const { canUsePaymentRequest, paymentRequest } = this.state;

      return (
        <div className="checkout-form">
          <h6>{this.serverData.hasSubcription ? 'Purchase Your Plan' : 'Make a payment'}</h6>
          <form onSubmit={this.getStripeSource}>
            <div className="stripe-label">Card Number</div>
            <div className="elements">
              <div
                className={joinClass(
                  'stripe-container',
                  this.state.cardError ? 'stripe-container--error' : '',
                )}
              >
                <CardNumberElement
                  style={stripeStyles}
                  ref={cardNumber => {
                    this.cardNumber = cardNumber;
                  }}
                  onChange={e => this.onChangeCardField(e, 'card')}
                  placeholder="Card Number"
                />
              </div>

              <div
                className={`${joinClass(
                  'stripe-container stripe-container--position',
                  this.state.expirationError ? 'stripe-container--error' : '',
                )}
                        `}
              >
                <div className="stripe-label stripe-label--position">Expiration Date</div>

                <CardExpiryElement
                  ref={cardExpiry => {
                    this.cardExpiry = cardExpiry;
                  }}
                  style={stripeStyles}
                  onChange={e => this.onChangeCardField(e, 'expiration')}
                />
              </div>

              <div
                className={`${joinClass(
                  'stripe-container stripe-container--position',
                  this.state.expirationError ? 'stripe-container--error' : '',
                )}
                        `}
              >
                <div className="stripe-label stripe-label--position">CVC</div>

                <CardCVCElement
                  ref={cardCVC => {
                    this.cardCVC = cardCVC;
                  }}
                  style={stripeStyles}
                  onChange={e => this.onChangeCardField(e, 'cvc')}
                />
              </div>
            </div>

            {errors.length > 0 && (
              <div className="stripe-errors">
                <ul>
                  {errors.map((e, i) => (
                    <li key={i}>
                      <span className="warning-icon" />
                      {e}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="credit-card-wrapper">
              <div
                className={`credit-card cc-visa ${
                  this.state.brand === 'visa' ? 'active' : 'inactive'
                }`}
              />
              <div
                className={`credit-card cc-mastercard ${
                  this.state.brand === 'mastercard' ? 'active' : 'inactive'
                }`}
              />
              <div
                className={`credit-card cc-american-express ${
                  this.state.brand === 'amex' ? 'active' : 'inactive'
                }`}
              />
              <div
                className={`credit-card cc-discover ${
                  this.state.brand === 'discover' ? 'active' : 'inactive'
                }`}
              />
            </div>

            <div className="lockflow">
              <div className="lock" />
              <div>
                <p className="locklabel">
                  All transactions are SSL (Secure Socket Layer) protected.
                </p>
              </div>
            </div>

            {!this.subscriptionOnly && (
              <div className="promobox">
                {this.state.showPromo ? (
                  <div className="code-flow">
                    <input
                      className="text-input text-input--5"
                      onChange={e =>
                        this.setState({
                          couponCode: e.target.value,
                        })
                      }
                      placeholder="Enter a Gift code"
                    />
                    <span className="promobox__apply" onClick={this.applyCoupon}>
                      Apply
                    </span>
                  </div>
                ) : (
                  <div
                    className="promobox__label"
                    onClick={() =>
                      this.setState({
                        showPromo: !this.state.showPromo,
                      })
                    }
                  >
                    I have a gift code
                  </div>
                )}

                {(this.state.couponSuccess || this.state.couponError) && (
                  <div className="stripe-errors">
                    <ul>
                      {this.state.couponError && (
                        <li>
                          <span className="warning-icon" />
                          {this.state.couponError}
                        </li>
                      )}
                      {this.state.couponSuccess && (
                        <li>
                          <span className="success-icon" />
                          {this.state.couponSuccess}
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </div>
            )}

            <div>
              <button
                type="submit"
                className="button button--primary button--fullwidth button--paybutton"
                disabled={this.state.isPaymentPending}
              >
                {this.state.isPaymentPending ? 'Processing...' : 'Secure Pay'}
              </button>
            </div>

            {canUsePaymentRequest && (
              <PureButton onClick={paymentRequest.show} className={this.paymentRequestButtonClass}>
                <span className="pay-icon">Pay with</span>
              </PureButton>
            )}
          </form>

          <CheckoutFooter />
        </div>
      );
    }

    componentDidMount() {
      super.componentDidMount();

      this.analyticsEvents.dispatchEvent('viewedPayment');

      API.getPriceInfo(
        this.state.couponCode,
        this.props.documentBundle,
        this.props.subscriptionPlan,
        this.includedRealEstate,
      )
        .then(data => {
          const companyName = getCompanyName(this.serverData);
          const paymentRequest = this.props.stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: `${companyName} ${this.props.documentBundle} estate planning bundle`,
              amount: data.discountedPrice,
            },
          });

          paymentRequest.on('token', ({ complete, token }) => {
            this.handleSubmit(token).then(success => complete(success ? 'success' : 'fail'));
          });

          paymentRequest.canMakePayment().then(result => {
            this.setState({
              canUsePaymentRequest: Boolean(result),
            });
          });

          this.props.updatePriceInfo(data);
          this.setState({
            price: data.discountedPrice / 100,
            paymentRequest,
          });
        })
        .catch(() => this.props.updatePriceInfo(null));
    }

    render() {
      if (!this.state.loaded) {
        return null;
      }

      switch (this.state.mode) {
        case 'pay':
          return this.renderPay();
        case 'success':
          return this.renderSuccess();
        case 'failure':
          return this.renderFailure();
        default:
          return this.renderPay();
      }
    }
  },
);
