import React, { useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { getPlanSections } from 'ui/sections/sections';
import SectionsDisplay from '@c/dashboard/SectionsDisplay';
import DashboardLayout from '@c/dashboard/DashboardLayout';
import Spacer from '@wui/layout/spacer';

import { useDocumentTitle, useGlobalContext } from 'hooks';
import { slugs, Urls } from 'urls';
import Header from './Header';

const PlanDashboard = ({ title }) => {
  useDocumentTitle(title);

  const { clientData, serverData, experiments } = useGlobalContext();
  const planSections = useMemo(() => getPlanSections(clientData, experiments), [
    clientData,
    experiments,
  ]);

  if (serverData.fromPortunus && !clientData.isStatusValid('sawMlpBundle')) {
    return <Redirect to={slugs.aboutYou} />;
  }

  if (serverData.hasNotarizedOnline) {
    return <Redirect to={Urls.file} />;
  }

  if (serverData.hasProofNotarized) {
    return <Redirect to={Urls.signSection} />;
  }

  return (
    <DashboardLayout showPlanHealth={false}>
      <Header />

      <Spacer v={14} only="xs" />
      <Spacer v={48} smUp />

      <SectionsDisplay sections={planSections} firstLabel="CHECKLIST" />
    </DashboardLayout>
  );
};

PlanDashboard.propTypes = {
  title: PropTypes.title,
};

export default observer(PlanDashboard);
